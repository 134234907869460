import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
//@ts-ignore
import { debounce } from 'lodash';
import React from "react";
var _ = require('lodash')
toast.configure()
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: any
  chatClient: any
  profileData: {
    location: string,
    information: string,
    emailId: string,
    countryCode:string,
    phoneNumber: string,
    dateOfBirth: string,
    profileImage: string,
    fullName: string,
    firstName: string
		lastName: string
    userName: string,
    country: string,
    state: string,
    city: string,
    userIdResetPassword: string
  }
  notificationsConfigurations: {
    emailAcceptReq: boolean,
    emailPostReq: boolean,
    emailReceiveReq: boolean,
    pushAcceptReq: boolean,
    pushPostReq: boolean,
    pushReceiveReq: boolean,
    smsReceiveReq: boolean,
    smsAcceptReq: boolean,
    smsPostReq: boolean
  }
  setProfile: (profileData: any) => void
  setNotificationsConfigurations: (configurationsDetails: any) => void
  userLogout: () => void
  userChange: () => void
  eraseFriendList: () => void
  deleteChats: () => void
}

export interface S {
  // Customizable Area Start

  value: number;
  isEditFullName: boolean;
  isEditUserLink: boolean;
  isEditDOB: boolean;
  isEditLocation: boolean;
  isEditPhone: boolean;
  countryData: any[];
  stateData: any[];
  cityData: any[];
  city: string;
  state: string;
  country: string;
  isTwoFactAuth: boolean
  AccountInfo: boolean;
  Notifications: boolean;
  Security: boolean;
  HelpCenter: boolean;
  Logout: boolean;
  ContactUs: boolean;
  notify_receive_request: boolean;
  notify_accept_request: boolean;
  notify_post_comment_reply: boolean;
  push_receive_request: boolean;
  push_accept_request: boolean;
  push_post_comment_reply: boolean;
  FaQList: [];
  searchedVal: string;
  userIdResetPassword: string;
  openDialogueBox: boolean;
  openActiveBox: boolean;
  allSearchedFaq: [];
  FaQListCopy: [];
  loader: boolean;
  isActive: boolean;
  logoutLoader: boolean,
  passowordChangeLoader: boolean;
  userName: string;
  profileUpdateLoader: 'name' | 'user name' | 'dob' | 'location' | 'phone number' | null;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class userAccountSettingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiMyInformationId: string = "";
  myInfoUpdateApiId: string;
  updateNameApiId: any
  updateUserNameApiId: any
  GetCountryListApiCallId: string = '';
  GetCountryStateListApiCallId: string = '';
  GetCountryCityListApiCallId: string = '';
  PushAndEmailNotificationId: any;
  FaQEndApiId: any;
  ContactUsApiId: any;
  resetPasswordApiId: any;
  apiMyAccountInformationId: any;
  deactivateAccountApiId: any;
  twoFactAuthApiId: any
  userNameRef: any
  logoutApiId: string = ""
  domainName: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.userNameRef = React.createRef()

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      value: 0,
      countryData: [],
      stateData: [],
      cityData: [],
      city: '',
      state: '',
      country: '',
      isEditFullName: false,
      isEditUserLink: false,
      isEditDOB: false,
      isEditLocation: false,
      isEditPhone: false,
      isTwoFactAuth: false,

      AccountInfo: true,
      Notifications: false,
      Security: false,
      HelpCenter: false,
      ContactUs: false,
      Logout: false,

      notify_receive_request: true,
      notify_accept_request: true,
      notify_post_comment_reply: true,
      push_receive_request: true,
      push_accept_request: true,
      push_post_comment_reply: true,
      FaQList: [],
      searchedVal: '',
      userIdResetPassword: '',
      openDialogueBox: false,
      openActiveBox: false,
      allSearchedFaq: [],
      FaQListCopy: [],
      loader: false,
      isActive: true,
      logoutLoader: false,
      passowordChangeLoader: false,
      userName: '',
      profileUpdateLoader: null
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }
  //Customizable area start
  async componentDidMount() {
    // Customizable Area Start
    //@ts-ignore
    if (Cookies.get("Login_Token")) {

      this.domainName = window.location.origin
      this.setState({ userName: this.props.profileData.userName })
      this.GetMyInformationAPI()
      this.callGetCountrylistApi()
      this.GetFaQ_Data()

      //@ts-ignore
      const response = this.props?.location?.state?.detail;
      if (response == 4) {
        this.setState({
          value: 4,
        });
      }
    } else {
      this.logoutUser()
    }
    // Customizable Area End
  }

  async componentDidUpdate(prevProp: any) {
    if (prevProp.profileData.country !== this.props.profileData.country) {
      this.getStatesByCountryNameApi(this.props.profileData.country)
      this.getCitiesByStateNameApi(this.props.profileData.state, this.props.profileData.country)
    }
    if (prevProp.profileData.state !== this.props.profileData.state) {
      this.state.country && this.getCitiesByStateNameApi(this.props.profileData.state, this.props.profileData.country)
    }
  }

  SaveSchema = Yup.object().shape({
    phoneNumber: Yup.string().notRequired()
      .matches(configJSON.phoneRegex, configJSON.phoneInValid),
  });

  fAQSchema = Yup.object().shape({
    current_password: Yup.string()
      .required("Current Password is required"),
    new_password: Yup.string()
      .required("New Password is required")
      .min(8, configJSON.passwordMinlength)
      .matches(configJSON.passwordRegex, configJSON.passwordInValid),
    confirm_password: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("new_password"), null], configJSON.errorBothPasswordsNotSame)
  });

  sortData = (list: any[], sortProperty: string) => {
    const tempList = [...list]
    tempList.sort((a: any, b: any) => {
      let fa = a[sortProperty].toLowerCase(),
        fb = b[sortProperty].toLowerCase();

      if (fa < fb) {
        return -1
      }
      if (fa > fb) {
        return 1
      }
      return 0
    })
    return tempList
  }

  //Api For getting CountryLists
  callGetCountrylistApi = (): boolean => {
    const headers = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "X-CSCAPI-KEY": configJSON.countryApiKey,
      "user-email": configJSON.headerForUserEmail
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetCountryListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllCountriesListUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getStatesByCountryNameApi = (value: any): boolean => {
    if (value) {
      const headers = {
        "Content-Type": configJSON.contentTypeApiAddDetail,
        "X-CSCAPI-KEY": configJSON.countryApiKey,
        "user-email": configJSON.headerForUserEmail
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.GetCountryStateListApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getAllStatesListByCountryUrl + value + '/states'
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'GET'
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      return false;
    }
  };

  cancelDeactivation = () => {
    this.setState({ ...this.state, isActive: true, openActiveBox: false })
  }

  handleDeactivation = () => {
    this.setState({ loader: true })
    this.deactivateUserAccount()
  }

  deactivateUserAccount = (): boolean => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get('Login_Token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deactivateAccountApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deactivateAccountEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleTwoFactorAuthentication = (value: boolean): boolean => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get('Login_Token')
    };

    const httpBody = {
      "account": {
        "two_factor_auth": value
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.twoFactAuthApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.twoFactorAuthenticationEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  //Api to get Country's CityLists
  getCitiesByStateNameApi = (state: string, country: string): boolean => {
    if (state) {
      const headers = {
        "Content-Type": configJSON.contentTypeApiAddDetail,
        "X-CSCAPI-KEY": configJSON.countryApiKey,
        "user-email": configJSON.headerForUserEmail
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.GetCountryCityListApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getAllCityListByStatesAndCountryUrl + country + '/states/' + state + '/cities'
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'GET'
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      return false;
    }
  };

  logoutUser = async () => {

    try {

      await this.props.chatClient.unsubscribeFromPushNotifications()
      await this.props.chatClient.shutdown()

    } catch (error) {
      console.log(error)
    }

    Cookies.remove('Login_Token')
    Cookies.remove("notification")
    Cookies.remove('Chat_Token')
    Cookies.remove('Login_Id')
    Cookies.remove('Login_Email')
    Cookies.remove('Account_Verified')
    
    this.props.userLogout()
    
    this.props.userChange()
    
    this.props.eraseFriendList()
    
    this.props.deleteChats()
    
    this.props.history.push('/login')
  }

  updatePushAndEmailNotification(type?: string) {

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": Cookies.get('Login_Token')
    };

    const {
      emailReceiveReq,
      emailAcceptReq,
      emailPostReq,
      pushReceiveReq,
      pushAcceptReq,
      pushPostReq,
      smsReceiveReq,
      smsAcceptReq,
      smsPostReq
    } = this.props.notificationsConfigurations

    const httpBody = {
      'account': {
        "notify_receive_request": type === 'emailReceiveReq' ? !emailReceiveReq : emailReceiveReq,
        "notify_accept_request": type === 'emailAcceptReq' ? !emailAcceptReq : emailAcceptReq,
        "notify_post_comment_reply": type === 'emailPostReq' ? !emailPostReq : emailPostReq,
        "push_receive_request": type === 'pushReceiveReq' ? !pushReceiveReq : pushReceiveReq,
        "push_accept_request": type === 'pushAcceptReq' ? !pushAcceptReq : pushAcceptReq,
        "push_post_comment_reply": type === 'pushPostReq' ? !pushPostReq : pushPostReq,
        "msg_receive_request": type === 'smsReceiveReq' ? !smsReceiveReq : smsReceiveReq,
        "msg_accept_request": type === 'smsAcceptReq' ? !smsAcceptReq : smsAcceptReq,
        "msg_post_comment_reply": type === 'smsPostReq' ? !smsPostReq : smsPostReq,
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.PushAndEmailNotificationId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.PushAndEmailNotificationEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  GetMyInformationAPI(): boolean {
    this.setState({ loader: true })
    const header = {
      "Content-Type": configJSON.contenttypeApiValidateMobileNo,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiMyInformationId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.myInfoApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  GetMyAccountDetails = (accountId: any): boolean => {

    const header = {
      "Content-Type": configJSON.contenttypeApiValidateMobileNo,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiMyAccountInformationId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountDetailsEndPoints + accountId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  GetFaQ_Data(): boolean {

    const header = {
      "Content-Type": configJSON.contenttypeApiValidateMobileNo,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.FaQEndApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.FaQEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  debounceLog = debounce((text: any) => {
    if (text) {
      const data = this.state.FaQList.filter((element: any) => {
        return element.attributes.question.toLowerCase().includes(text.toLowerCase());
      });

      //@ts-ignore
      this.setState({ FaQList: data, searchedVal: text })
      //@ts-ignore
      document.getElementById('searchHelpCenter').value = text
      //@ts-ignore
      document.getElementById('searchHelpCenter').focus()


    } else {
      //@ts-ignore
      this.setState({ FaQList: this.state.FaQListCopy, searchedVal: '' })
      //@ts-ignore
      document.getElementById('searchHelpCenter').value = ''
      //@ts-ignore
      document.getElementById('searchHelpCenter').focus()

    }
  }, 500)

  handleChangeDebounce = (value: any) => {
    this.debounceLog(value)
  }

  contactUsRequest(values: any): boolean {

    const header = {
      "Content-Type": configJSON.contenttypeApiValidateMobileNo,
      "token": Cookies.get("Login_Token"),
    };
    const attribute = {
      "subject": values.subject,
      "message": values.message
    }
    const attributes = {
      attributes: attribute
    };

    const httpBody = {
      data: attributes
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    this.ContactUsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ContactUsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  resetPasswordRequest(values: any, userId: any): boolean {

    this.setState({ passowordChangeLoader: true })

    const header = {
      "Content-Type": configJSON.contenttypeApiValidateMobileNo,
      "token": Cookies.get("Login_Token"),
    };
    const attributes = {
      "current_password": values.current_password,
      "new_password": values.new_password
    }
    const httpBody = {
      data: attributes
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    this.resetPasswordApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resetPassword + userId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPatchMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  updateInformationAPI(profileDetails: any): boolean {

    const changesData = {
      date_of_birth: profileDetails.dateOfBirth,
      mobile_number: profileDetails.phoneNumber,
      state: profileDetails.state,
      country: profileDetails.country,
      city: profileDetails.city,
      country_code: profileDetails.countryCode
    }

    const {
      dateOfBirth,
      phoneNumber,
      state,
      country,
      city,
      countryCode
    } = this.props.profileData

    const oldData = {
      date_of_birth: dateOfBirth,
      mobile_number: phoneNumber,
      state: state,
      country: country,
      city: city,
      country_code: countryCode
    }

    const isEqual = _.isEqual(changesData, oldData)

    if (!isEqual) {
      const header = {
        "Content-Type": configJSON.contenttypeApiValidateMobileNo,
        "token": Cookies.get("Login_Token"),
      };

      const profileData = {
        about: "",
        ...changesData,
      };

      const httpBody = {
        profile: profileData
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      this.myInfoUpdateApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.myInfoUpdateEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPutMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

      return true;
    } else {
      this.setState({ isEditLocation: false, isEditDOB: false, isEditPhone: false, profileUpdateLoader: null })
      return false;
    }
  }

  updateUserFullName(fullName: { firstName: string, lastName: string }): boolean {
    const { firstName: fName, lastName: lName } = this.props.profileData
    const { firstName, lastName } = fullName
    
    if (fName === firstName && lName === lastName) {
      this.setState({ isEditFullName: false })
    } else {
      this.setState({ isEditFullName: false, profileUpdateLoader: "name" })
      const header = {
        "Content-Type": configJSON.contenttypeApiValidateMobileNo,
        "token": Cookies.get("Login_Token"),
      };

      const nameOfUser = {
        "first_name": fullName.firstName,
        "last_name": fullName.lastName
      }

      const httpBody = {
        account: nameOfUser
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      this.updateNameApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updateUserFullNameEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPutMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

      return true;
    }
    return false;
  }

  updateUserName(name: string): boolean {
    if ((name === this.props.profileData.userName) || !name) {
      this.setState({ isEditUserLink: false })
    } else {
      this.props.setProfile({ userName: name })
      this.setState({ profileUpdateLoader: "user name" })
      const header = {
        "Content-Type": configJSON.contenttypeApiValidateMobileNo,
        "token": Cookies.get("Login_Token"),
      };

      const userNameData = {
        "user_name": name,
      }

      const httpBody = {
        account: userNameData
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      this.updateUserNameApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updateUserNameEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPutMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

      return true;
    }
    return false
  }

  RedirectToUser = (values: any, iseUserName = false) => {
    if (iseUserName) {
      //@ts-ignore
      this.props.history.push({
        pathname: '/' + values,
        state: { detail: values }
      })
    } else {
      //@ts-ignore
      this.props.history.push({
        pathname: '/user/' + values,
        state: { detail: values }
      })
    }
  }

  handleLogout = ():boolean => {
    this.setState({ logoutLoader: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token" :Cookies.get('Login_Token')
    };

    const httpBody = {
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.logoutApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.logoutEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId != null) {
          //CreateAccount response Api
          if (
            apiRequestCallId === this.apiMyInformationId &&
            responseJson !== undefined
          ) {

            const profileData = {
              location: responseJson?.data?.attributes?.city,
              information: responseJson?.data?.attributes?.about,
              emailId: responseJson?.data?.attributes?.email,
              phoneNumber: responseJson?.data?.attributes?.mobile_number,
              dateOfBirth: responseJson?.data?.attributes?.date_of_birth,
              profileImage: responseJson?.data?.attributes?.image,
              fullName: responseJson?.data?.attributes?.full_name,
              userName: responseJson?.data?.attributes?.user_name,
              firstName: responseJson?.data?.attributes?.first_name,
              lastName: responseJson?.data?.attributes?.last_name,
              country: responseJson?.data?.attributes?.country,
              state: responseJson?.data?.attributes?.state,
              city: responseJson?.data?.attributes?.city,
              userIdResetPassword: responseJson?.data?.attributes?.account_id,
              countryCode: responseJson?.data?.attributes?.country_code,
              isTwoFactAuth: responseJson?.data?.attributes?.two_factor_auth_status
            }

            this.props.setProfile(profileData)

            !!profileData.country && this.getStatesByCountryNameApi(profileData.country)
            !!profileData.state && this.getCitiesByStateNameApi(profileData.state, profileData.country)

            this.setState({ city: responseJson?.data?.attributes?.city })
            this.setState({ state: responseJson?.data?.attributes?.state })
            this.setState({ country: !responseJson?.data?.attributes?.country ? "default" : responseJson?.data?.attributes?.country })
            this.setState({ userIdResetPassword: responseJson?.data?.attributes?.account_id })
            this.setState({ isTwoFactAuth: responseJson?.data?.attributes?.two_factor_auth_status })
            this.setState({ userName: responseJson?.data?.attributes?.user_name, })
            this.GetMyAccountDetails(responseJson?.data?.attributes?.account_id)
            this.setState({ loader: false })
          }
          if (
            apiRequestCallId === this.updateNameApiId &&
            responseJson !== undefined) {
              toast.success(responseJson?.meta?.message, { position: toast.POSITION.BOTTOM_RIGHT })
              this.props.setProfile({ firstName: responseJson.data.attributes.first_name, lastName: responseJson.data.attributes.last_name, fullName: responseJson.data.attributes.full_name })
              this.setState({ profileUpdateLoader: null })
          }
          if (
            apiRequestCallId === this.updateUserNameApiId &&
            responseJson !== undefined
          ) {
            toast.success(responseJson?.meta?.message, { position: toast.POSITION.BOTTOM_RIGHT })
            this.props.setProfile({ userName: responseJson.data.attributes.user_name })
            this.setState({ ...this.state, isEditUserLink: false, profileUpdateLoader: null })
          }
          if (
            apiRequestCallId === this.myInfoUpdateApiId &&
            responseJson !== undefined
          ) {
            toast.success(responseJson?.meta?.message, { position: toast.POSITION.BOTTOM_RIGHT })
            const profileData = {
              location: responseJson?.data?.attributes?.city,
              information: responseJson?.data?.attributes?.about,
              emailId: responseJson?.data?.attributes?.email,
              phoneNumber: responseJson?.data?.attributes?.mobile_number,
              dateOfBirth: responseJson?.data?.attributes?.date_of_birth,
              profileImage: responseJson?.data?.attributes?.image,
              fullName: responseJson?.data?.attributes?.full_name,
              userName: responseJson?.data?.attributes?.user_name,
              firstName: responseJson?.data?.attributes?.first_name,
              lastName: responseJson?.data?.attributes?.last_name,
              country: responseJson?.data?.attributes?.country,
              state: responseJson?.data?.attributes?.state,
              city: responseJson?.data?.attributes?.city,
              userIdResetPassword: responseJson?.data?.attributes?.account_id,
              countryCode: responseJson?.data?.attributes?.country_code,
              isTwoFactAuth: responseJson?.data?.attributes?.two_factor_auth_status
            }
            this.props.setProfile(profileData)
            this.setState({ profileUpdateLoader: null })
          }
          if (
            apiRequestCallId === this.apiMyAccountInformationId &&
            responseJson !== undefined
          ) {

            const notificationsConfigurations = {
              emailAcceptReq: responseJson.data.attributes.notify_accept_request,
              emailPostReq: responseJson.data.attributes.notify_post_comment_reply,
              emailReceiveReq: responseJson.data.attributes.notify_receive_request,
              pushAcceptReq: responseJson.data.attributes.push_accept_request,
              pushPostReq: responseJson.data.attributes.push_post_comment_reply,
              pushReceiveReq: responseJson.data.attributes.push_receive_request,
              smsReceiveReq: responseJson.data.attributes.msg_receive_request,
              smsAcceptReq: responseJson.data.attributes.msg_accept_request,
              smsPostReq: responseJson.data.attributes.msg_post_comment_reply
            }
            this.props.setNotificationsConfigurations(notificationsConfigurations)
          }
          //CountryList Api
          if (
            apiRequestCallId === this.GetCountryListApiCallId &&
            responseJson !== undefined
          ) {
            const sortList = this.sortData(responseJson, "name")
            
            this.setState({ ...this.state, countryData: sortList, loader: false })
          }
          //CountryStateList Api
          if (
            apiRequestCallId === this.GetCountryStateListApiCallId &&
            responseJson !== undefined
          ) {
            const sortList = this.sortData(responseJson, "name")
            this.setState({ ...this.state, stateData: sortList, loader: false })
          }
          //Country City Api
          if (
            apiRequestCallId === this.GetCountryCityListApiCallId &&
            responseJson !== undefined
          ) {
            const sortList = this.sortData(responseJson, "name")
            this.setState({ ...this.state, cityData: sortList, loader: false })
          }
          if (
            apiRequestCallId === this.deactivateAccountApiId &&
            responseJson !== undefined
          ) {
            this.setState({ isActive: false })
            this.logoutUser()
            toast.success(responseJson?.Message, { position: toast.POSITION.BOTTOM_RIGHT })
          }
          if (
            apiRequestCallId === this.twoFactAuthApiId &&
            responseJson !== undefined
          ) {
            this.setState({ ...this.state, loader: false })
            toast.success("Two-factor authentication updated successfully", { position: toast.POSITION.BOTTOM_RIGHT })
          }
          //Country City Api
          if (
            apiRequestCallId === this.PushAndEmailNotificationId &&
            responseJson !== undefined
          ) {
            this.GetMyAccountDetails(this.props.profileData.userIdResetPassword)
          }
          if (
            apiRequestCallId === this.FaQEndApiId &&
            responseJson !== undefined
          ) {

            this.setState({ FaQList: responseJson.data, FaQListCopy: responseJson.data })
          }
          if (
            apiRequestCallId === this.resetPasswordApiId &&
            responseJson !== undefined
          ) {
              this.setState({ passowordChangeLoader: false })
              toast.success('Password Updated Successfully', { position: toast.POSITION.BOTTOM_RIGHT })
          }
          if (
            apiRequestCallId === this.ContactUsApiId &&
            responseJson !== undefined
          ) {

            toast.success("Your request submitted successfully", { position: toast.POSITION.BOTTOM_RIGHT })
          }
          if (
            apiRequestCallId === this.logoutApiId &&
            !!responseJson
          ) {
            this.setState({ logoutLoader: false,openDialogueBox: false })
            this.logoutUser()
            toast.success("Logout successfully", { position: toast.POSITION.BOTTOM_RIGHT })
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
        const errors = responseJson?.errors;

        if (errors) {
          if(apiRequestCallId === this.resetPasswordApiId) {
            this.setState({ passowordChangeLoader: false })
          }

          if(apiRequestCallId === this.myInfoUpdateApiId) {
            this.setState({ profileUpdateLoader: null })
          }

          if (errors[0].profile) {
            toast.error(errors[0].profile, { position: toast.POSITION.BOTTOM_RIGHT })
            return
          }
          if (errors[0]?.profile?.image[0] === "Only JPEG, JPG and PNG file extensions are allowed.") {
            toast.error(errors[0]?.profile?.image[0], { position: toast.POSITION.BOTTOM_RIGHT })
          }
          else if (errors[0]?.subject || errors[1]?.message) {
            toast.error('subject or message ' + errors[0].subject, { position: toast.POSITION.BOTTOM_RIGHT })
          }
          else if (errors[0] === "Username already exists") {
              toast.error(errors[0], { position: toast.POSITION.BOTTOM_RIGHT })
              this.setState({ profileUpdateLoader: null })
              this.props.setProfile({ userName: this.state.userName })
              this.userNameRef.current.focus()
          }
          else if (errors) {
            toast.error(responseJson?.errors, { position: toast.POSITION.BOTTOM_RIGHT })
          }
        }
        else {
          toast.error(errors, { position: toast.POSITION.BOTTOM_RIGHT })

        }
      }
    }
  }
  // Customizable Area End
}
