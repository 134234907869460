import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import * as Yup from "yup";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Web3 from "web3";
import { InjectedConnector } from "@web3-react/injected-connector";
//@ts-ignore
import { debounce } from 'lodash';
toast.configure()
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  location: any
  history: any
  list: any
  profileData: {
    location: string,
    information: string,
    emailId: string,
    countryCode: string,
    phoneNumber: string,
    dateOfBirth: string,
    profileImage: string,
    fullName: string,
    firstName: string
    lastName: string
    userName: string,
    country: string,
    state: string,
    city: string,
    userIdResetPassword: string
  }
  deleteUserchat: (userId: any, func: any) => void
  deleteLastMesg: (userId: any, func: any) => void
  userLogout: () => void
  // Customizable Area End
}

export interface S {
  // Customizable Area Start

  otpAuthToken: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  submitBtn: boolean;
  reCaptchaVarified: boolean;
  value: number;
  showSaveBtn: boolean;
  showDatePicker: boolean;
  inputFieldDisable: boolean;
  labelLocation: string;
  lastPhone: string;
  labelEmail: string;
  labelInfo: string;
  labelBirthadate: string;

  responseLocation: any;
  responseInformation: any;
  responsePhone: any;
  responseEmail: any;
  responseBirthDate: any;
  selectedProfileImage: any;
  profileImage: any;
  fullName: any;
  userName: any;

  countryData: any[];
  stateData: any[];
  cityData: any[];
  CountryName: string;
  nameOfCountry: string;
  nameOfState: string;
  nameOfCity: string;
  city: string;
  state: string;
  country: string;

  myNftopen: boolean;
  myNftopenChild: boolean;
  firstName: any;
  lastName: any;
  myNftallAssets: [];
  myNftconfirm: boolean;
  myNfttokenId: any;
  myNftmetamaskOwnerId: any;
  myNftcheckAccount: number;
  myNftmetamaskBTN: boolean;
  myNftuploadNFT: boolean;
  setImgUrl: any;
  anchorEl: any;
  responseCity: string;
  responseState: string;
  responseCountry: string;
  myAllPost: [];
  myAllNfts: [];
  nftCount: number;
  userId: any;
  addFriendBtn: any;
  openDialoug: boolean;
  allFriendList: [];
  friendId: any;
  openBlockDialogue: boolean;
  blockFriendId: any;
  connectionId: any;
  followStatus: boolean;
  receipentId: any;
  requestCreatedBy: number;
  friendUnfriendState: boolean;
  getFriendOfFriend: [];
  accountId: any;
  frdCount: number;
  isBlocked: any;
  callDidMountHandle: string;
  searchedValaddFriend: any;
  doNotCall: any;
  changePageId: any;
  showBackDrop: boolean;
  commentBoxId: any;
  allComments: [];
  commentCount: number;
  showReplyInbox: any;
  allRepliedComments: [];
  reportDialog: boolean;
  confirmReportDialog: boolean;
  reportDialogMessage: string;
  reportReasons: any;
  reportSubReasons: any;
  postReportData: {
    post_id: number,
    report_reason_id: number
  }
  userNFTs: any;
  postLoader: boolean;
  requestSendLoader: boolean
  editCommentId: number
  deleteCommentDialog: boolean
  deleteCommentId: number
  isAccountExist: boolean
  commentFilter: number
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AddFriendsProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;
  labelLocation: string;
  lastPhone: string;
  labelEmail: string;
  labelBirthadate: string;
  labelInfo: string;
  currentCountryCode: any;
  apiMyInformationId: string = "";
  apiImageUploadId: string = "";

  responseLocation: any;
  responseInformation: any;
  responsePhone: any;
  responseEmail: any;
  responseBirthDate: any;
  myInfoUpdateApiId: string;
  profilePictureApiCallId: any;

  GetCountryAuthTokenApiCallId: string = '';
  GetCountryListApiCallId: string = '';
  GetCountryStateListApiCallId: string = '';
  GetCountryCityListApiCallId: string = '';
  openseaAccountApiCallId: any;
  myNftopenSeaApiCallId: any;
  myNftcreateWalletAccountApiCallId: any;
  myNftcreateNFTAccountApiCallId: any;
  myNftapiMetaMaskWalletsId: string;
  allMyNftPostsapiId: any;
  deletePostApiId: any;
  likePostApiId: any;
  dislikePostApiId: any;
  verifiedNFTApiCallId: any;
  allFirendsApiId: any;
  getUserIdApiId: any;
  singleFirendsApiId: any;
  userProfileId: any
  friendApiId: any
  unfollowFriendApi: any;
  unfriendApiId: any;
  getFriendOfFriendId: any;
  blockApiId: any;
  followFriendApi: any;
  blockedUserApiId: any;
  searchFriendcallId: any;
  createCommentApiId: any;
  commentDataApiId: any;
  createLikeonCommentApiId: any;
  createReplyCommentApiId: any
  commentReplyDataApiId: any;
  reportReasonsApiId: any;
  postReportApiId: any;
  getUserNFTsApiId: any;
  editCommentApiId: string = ""
  deleteCommentApiId: string = ""
  cancelRequestApiId: string = ""
  acceptRequestApiId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      labelLocation: configJSON.locationLabel,
      lastPhone: configJSON.phoneLabel,
      labelEmail: configJSON.emailLabel,
      labelBirthadate: configJSON.birthdateLabel,
      labelInfo: configJSON.informationLabel,
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      submitBtn: false,
      reCaptchaVarified: false,
      value: 0,
      showSaveBtn: true,
      showDatePicker: true,
      inputFieldDisable: true,
      responseInformation: "",
      responseBirthDate: "",
      responseEmail: "",
      responsePhone: "",
      responseLocation: "",
      selectedProfileImage: false,
      profileImage: "",
      fullName: "",
      userName: "",
      countryData: [],
      stateData: [],
      cityData: [],
      CountryName: '',
      nameOfCountry: '',
      nameOfState: '',
      nameOfCity: '',
      city: '',
      state: '',
      country: '',
      myNftopen: false,
      myNftopenChild: false,
      myNftallAssets: [],
      myNftconfirm: false,
      myNfttokenId: '',
      myNftmetamaskOwnerId: '',
      firstName: Cookies.get("first_name") ? Cookies.get("first_name") : 'Profile',
      lastName: Cookies.get("last_name") ? Cookies.get("last_name") : 'Name',
      myNftcheckAccount: 0,
      myNftmetamaskBTN: false,
      myNftuploadNFT: false,
      setImgUrl: '',
      anchorEl: '',
      responseCity: '',
      responseState: '',
      responseCountry: '',
      myAllPost: [],
      myAllNfts: [],
      nftCount: 2,
      userId: '',
      addFriendBtn: '',
      openDialoug: false,
      allFriendList: [],
      friendId: '',
      openBlockDialogue: false,
      blockFriendId: '',
      connectionId: '',
      followStatus: false,
      receipentId: '',
      requestCreatedBy: 0,
      friendUnfriendState: false,
      getFriendOfFriend: [],
      accountId: '',
      frdCount: 9,
      isBlocked: '',
      callDidMountHandle: "callOnce",
      searchedValaddFriend: '',
      doNotCall: '',
      changePageId: '',
      showBackDrop: true,
      commentBoxId: '',
      allComments: [],
      commentCount: 1,
      showReplyInbox: '',
      allRepliedComments: [],
      reportDialog: false,
      confirmReportDialog: false,
      reportDialogMessage: "",
      reportReasons: [],
      reportSubReasons: [],
      postReportData: {
        post_id: 0,
        report_reason_id: 0
      },
      userNFTs: [],
      postLoader: false,
      requestSendLoader: false,
      editCommentId: 0,
      deleteCommentDialog: false,
      deleteCommentId: 0,
      isAccountExist: false,
      commentFilter: 10
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  handleUserChange = () => {
    const url = window.location.href;
    const searchParam = url.substring(url.lastIndexOf('/') + 1)
    const lastParts = url.split("/").slice(-2)[0]

    if (lastParts === "user") {
      if (searchParam == Cookies.get("Login_Id")) {
        this.props.history.push('/myprofile')
      } else {
        this.userProfileId = searchParam
        this.getFriendProfile("")
      }
    } else {
      this.getUserId(searchParam)
    }
  }

  //Customizable area start
  async componentDidMount() {

    // Customizable Area Start
    if (Cookies.get("Login_Token")) {
      this.handleUserChange()
      this.getReportReasons()
    }
    // Customizable Area End
  }

  async componentDidUpdate(prevProp: any) {
    if (prevProp.location.pathname !== this.props.location.pathname) {
      this.handleUserChange()
    }
  }

  SaveSchema = Yup.object().shape({
    phoneNumber: Yup.string().notRequired()
      .matches(configJSON.phoneRegex, configJSON.phoneInValid),

  })

  logoutUser = () => {
    Cookies.remove('Login_Token')
    Cookies.remove("notification")
    Cookies.remove('Chat_Token')
    Cookies.remove('Login_Id')
    Cookies.remove('Login_Email')
    Cookies.remove('Account_Verified')
    this.props.userLogout()
    this.props.history.push('/login')
  }

  handleDeleteCommentToogle = (id?: number) => {
    if (!this.state.deleteCommentDialog && !!id) {
      this.setState({ deleteCommentDialog: true, deleteCommentId: id })
    } else {
      this.setState({ deleteCommentDialog: false, deleteCommentId: 0 })
    }
  }

  nftToPostRedirect = (postData: any) => {
    if (!!postData?.id) {
      this.props.history.push({
        pathname: '/post/' + postData?.id,
        state: { detail: postData?.id }
      })
    }
  }

  postTypeCheck = (url: string) => {
    const extension = url.split('.')?.pop()?.toLowerCase()
    if (!!extension) {
      if (configJSON.musicTypes.includes(extension)) {
        return "audio"
      } else if (configJSON.videoTypes.includes(extension)) {
        return "video"
      } else if (configJSON.object3dTypes.includes(extension)) {
        return "3dObject"
      } else {
        return "iframe"
      }
    } else {
      return "iframe"
    }
  }

  seeAllNft(allNft: any) {
    this.setState({ nftCount: allNft.length })
    this.verifiedNFT()
  }

  getLibrary(provider: any) {
    return new Web3(provider)
  }

  async connect(activate: any, account: any) {

    //@ts-ignore
    if (!window.web3) {
      toast.error('Cannot find MetaMask installed in your browser or Perhaps It is disabled in your browser ,Please install or enable it to continue...', { autoClose: 7000, position: toast.POSITION.BOTTOM_RIGHT })
    } else {
      this.setState({ myNftmetamaskBTN: true })
      const injected = new InjectedConnector({
        supportedChainIds: [1, 3, 4, 5, 42],
      });
      await activate(injected)
      //@ts-ignore
      await window.ethereum.enable();
      //@ts-ignore
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      let acc = accounts[0];
      if (Cookies.get("Login_Token") && acc) {
        this.getAllMyNftMetaMaskWallets()
      }
    }
  }

  handleUpload = async (value: any) => {
    const OwnerId = Cookies.get('MetaMask_Owner_Id')
    if (!OwnerId || OwnerId === 'undefined') {
      this.connect(value.activate,value.account)
    } else {
      //@ts-ignore
      await window.ethereum.enable();
      //@ts-ignore
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      let acc = accounts[0];
      if (acc === OwnerId) {
        this.setState({ myNftmetamaskBTN: true, myNftuploadNFT: true })
        this.myNftopenseaAccount(OwnerId)
      } else {
        this.getAllMyNftMetaMaskWallets()
      }
    }
  }

  selectNftImg(url: any, token_id: any) {
    this.setState({ setImgUrl: url, myNftopenChild: false })
    this.setState({ myNftconfirm: true })
    this.setState({ myNfttokenId: token_id })
  }

  myNftopenseaAccount = (account: any): boolean => {

    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.myNftopenSeaApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'https://api.opensea.io/api/v1/assets?owner=' + account + '&order_direction=desc&offset=0&limit=20'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handlePostReport = (): boolean => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get('Login_Token')
    };

    const httpBody = this.state.postReportData

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postReportApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reportPostEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getReportReasons(): boolean {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.reportReasonsApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reportReasonsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;

  }

  getUserId = (userName: string): boolean => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get('Login_Token')
    };

    const bodyData = {
      account: {
        "user_name": userName
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserIdApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserIdEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getUserNFTs = (): boolean => {
    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserNFTsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserNFTsEndpoint + this.userProfileId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getFriendsList = (): boolean => {
    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.allFirendsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.friendsListEndPoints
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  sendRequest = (): boolean => {
    this.setState({ requestSendLoader: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get('Login_Token')
    };

    const httpBody = {
      'receipient_id': this.state.userId,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.friendApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendFriendRequestEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }

  getFriendProfile = (userprofileId: any): boolean => {
    if (userprofileId) {
      this.setState({ userId: userprofileId })
    } else {
      this.setState({ userId: this.userProfileId })
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.singleFirendsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFriendProfileEndPoints + this.userProfileId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  blockfriendFunc = (accountId: any): boolean => {
    this.setState({ openBlockDialogue: false })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get('Login_Token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.blockApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.blockEndPoint + accountId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getAllMyNftPost(): boolean {
    this.setState({ postLoader: true })
    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.allMyNftPostsapiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFriendAllPostEndPoint + this.userProfileId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getAllMyNftMetaMaskWallets(): boolean {
    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.myNftapiMetaMaskWalletsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.myMetamaskEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  confirmUpload = (): boolean => {
    //@ts-ignore
    const b = document.querySelector("#bio");
    //@ts-ignore
    let desc = b.value;
    if (desc) {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": Cookies.get('Login_Token')
      };
      let name = this.state.firstName + " " + this.state.lastName;


      const httpBody = {
        name: name ? name : '',
        description: desc ? desc : '',
        token_id: this.state.myNfttokenId,
        owner_id: Cookies.get('MetaMask_Owner_Id')
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.myNftcreateNFTAccountApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.confirmNFTEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      return false;
    }
  }

  fileUploadHandler = async (e: React.FormEvent<EventTarget>) => {

    let target = e.target as HTMLInputElement;
    let files = target.files as any;
    let url = URL.createObjectURL(files[0])
    // eslint-disable-next-line no-restricted-syntax
    for (const file of files) {
      // eslint-disable-next-line no-await-in-loop
      // const fileInBase64 = await this.converFileToBase64(file);
      // eslint-disable-next-line camelcase
      const [name, file_type] = file.name.split(/(\.[^.]+)$/gm);

      this.setState({
        selectedProfileImage: file
      })
      //@ts-ignore
      const b = document.querySelector("#profilePicture");
      //@ts-ignore
      b.setAttribute("src", url)
      let formData = new FormData();    //formdata object
      formData.append('profile[image]', file);
      // Display the values
      //call API and pass formdata over there

      this.uploadProfilePicture(formData)
    }
  }

  uploadProfilePicture(values: any): boolean {

    const header = {
      "token": Cookies.get('Login_Token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.profilePictureApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ImageUploadApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      values
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  metamaskAccount = (account: any): boolean => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get('Login_Token')
    };

    const data = {
      wallet_type: "metamask",
      address: account
    };

    const httpBody = {
      wallet: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.myNftcreateWalletAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.metamaskWalletEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  unfriendFunc = (connectedId: any): boolean => {
    this.setState({ openDialoug: false })

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get('Login_Token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.unfriendApiId = requestMessage.messageId;
    if (connectedId) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.unfriendEndPoint + "/" + connectedId + "/unfriend"
      )
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.unfriendEndPoint + "/" + this.state.connectionId + "/unfriend"
      )
    }


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  verifiedNFT = (): boolean => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get('Login_Token')
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.verifiedNFTApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.veriFiedNftEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  deletePost = (postId: any): boolean => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get('Login_Token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deletePostApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deletePostEndPoint + "/" + postId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  likePost = (postId: any, is_Liked: any): boolean => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get('Login_Token')
    };
    if (!is_Liked) {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.likePostApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.likePostEndPoint + "/" + postId + "/like"
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.dislikePostApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.likePostEndPoint + "/" + postId + "/dislike"
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }

  unblockUser = (blockUserId: any): boolean => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get('Login_Token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.blockedUserApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.unBlockUserEndPoint + blockUserId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  debounceLogfrd = debounce((text: any) => {
    if (text) {
      this.searchingAddFriend(text)
      this.setState({ doNotCall: 'friends' })
    } else {
      let url = window.location.href;
      let id = url.substring(url.lastIndexOf('/') + 1);

      this.userProfileId = id
      this.setState({ doNotCall: '' })
      this.getFriendOfFriend(id)
    }
  }, 500)

  handleChangeDebounceAddFriend = (value: any) => {
    this.debounceLogfrd(value)
  }

  searchingAddFriend = (value: any): boolean => {

    this.setState({ searchedValaddFriend: value })

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.searchFriendcallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.searchFriendENdpoint + '=' + value + '&user_id=' + this.state.userId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    setTimeout(() => {
      //@ts-ignore
      document.getElementById('searchallFriends').value = value
    }, 1);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    setTimeout(() => {
      //@ts-ignore
      document.getElementById('searchallFriends').value = value
    }, 1);
    return true;
  }

  unfollowRequest = (isChecked: boolean): boolean => {
    if (isChecked) {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": Cookies.get('Login_Token')
      };

        const httpBody = {
          "connection_id": this.state.connectionId,
          "receipient_id": this.state.receipentId
        }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.unfollowFriendApi = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.unfollowReqEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPutMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else if (!isChecked) {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": Cookies.get('Login_Token')
      }

        const conctId = this.state.connectionId

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.followFriendApi = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.followFriendEndPoint + conctId
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPutMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      return false;
    }

  }

  RedirectToUser = (values: any, isUserName: boolean) => {
    const id = Cookies.get('Login_Id')
    if (id != values) {
      this.setState({ showBackDrop: true })

      if (isUserName) {
        this.props.history.push({
          pathname: '/' + values,
          state: { detail: values }
        })
      } else {
        this.props.history.push({
          pathname: '/user/' + values,
          state: { detail: values }
        })
      }

      this.componentDidMount()

      //location.reload()
    } else {
      this.props.history.push('/myprofile')
    }
  }

  callComponentDidMount = () => {
    this.setState({ showBackDrop: true })
    this.componentDidMount()
  }

  //comenting to post 
  createComment(values: any, idNum: any): boolean {

    //@ts-ignore
    const coment = document.getElementById('comentBox' + idNum).value
    if (coment) {
      const header = {
        "Content-Type": configJSON.contentTypeApiAddDetail,
        "token": Cookies.get("Login_Token"),
      };
      const attribute = {
        "post_id": values,
        "comment": coment,
      }
      const attributes = {
        attributes: attribute
      };

      const httpBody = {
        data: attributes
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      this.createCommentApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createCommentEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

      setTimeout(() => {
        //@ts-ignore
        document.getElementById('comentBox' + idNum).value = ''
      }, 10);
      return true;
    } else {
      return false;
    }
  }
  // comment data of post 
  getRepliedCommentData(values: any): boolean {
    if (values) {
      const header = {
        "Content-Type": configJSON.contentTypeApiAddDetail,
        "token": Cookies.get("Login_Token"),
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.commentReplyDataApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.commentReplyEndPoint + values
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

      return true;
    } else {
      return false;
    }
  }

  createReplyComment(postId: any, index: any, commentIndex: any, commentId: any): boolean {

    //@ts-ignore
    const coment = document.getElementById("comentReplyBox" + index + commentIndex).value
    if (coment) {
      const header = {
        "Content-Type": configJSON.contentTypeApiAddDetail,
        "token": Cookies.get("Login_Token"),
      };
      const attribute = {
        "post_id": postId,
        "comment": coment,
        "comment_id": commentId
      }
      const attributes = {
        attributes: attribute
      };

      const httpBody = {
        data: attributes
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      this.createReplyCommentApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createCommentEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

      setTimeout(() => {
        //@ts-ignore
        document.getElementById("comentReplyBox" + index + commentIndex).value = ''
      }, 10);
      return true;
    } else {
      return false;
    }
  }
  // comment data of post 
  getCommentData(values: any, type?: any): boolean {

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.commentDataApiId = requestMessage.messageId;
    if (type === 'oldest' || type === 'newest' || type === 'most_relevant') {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.commentdataEndPoint + values + "/comments?filter_param=" + type
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.commentdataEndPoint + values + "/comments"
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;

  }

  //like/dislike comments
  likeOrDislikeComment(values: any, liked: any): boolean {

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createLikeonCommentApiId = requestMessage.messageId;
    if (liked) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createCommentEndPoint + "/" + values + "/dislike"
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createCommentEndPoint + "/" + values + "/like"
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;

  }

  editComment = (postId: number) => {
    const b = document.querySelector("#editCommentBox") as HTMLInputElement;
    const desc = b?.value

    if (!!desc) {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": Cookies.get('Login_Token')
      };

      const httpBody = {
        data: {
          attributes: {
            post_id: postId,
            comment: desc
          },
          comment_id: this.state.editCommentId
        }
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.editCommentApiId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.editCommentEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      toast.error("Please write a comment", { position: toast.POSITION.BOTTOM_RIGHT })
    }
    return false
  }

  deleteComment = () => {
    if (this.state.deleteCommentId > 0) {
      this.handleDeleteCommentToogle()
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": Cookies.get('Login_Token')
      };

      const httpBody = {
        data: {
          comment_id: this.state.deleteCommentId
        }
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.deleteCommentApiId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.deleteCommentEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      toast.error("Something went wrong!", { position: toast.POSITION.BOTTOM_RIGHT })
      return false
    }
  }

  cancelFriendRequest = () => {
    this.setState({ requestSendLoader: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": Cookies.get('Login_Token')
    };

    const httpBody = {
      connection_id: this.state.connectionId
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.cancelRequestApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cancelFriendRequestEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  deleteChat = async (userId: any) => {
    const myLoginId = Cookies.get("Login_Id") || this.props.profileData.userIdResetPassword
    let roomId = ''
    if (Number(myLoginId) > Number(userId)) {
      roomId = `${userId}_${myLoginId}`
    } else {
      roomId = `${myLoginId}_${userId}`
    }

    if (this.props.list) {
      const client = this.props.list
      try {
        const channel = await client.getChannelByUniqueName(roomId)

        if (channel) {
          const memChannel = await channel.delete()

          if (memChannel) {
            this.props.deleteUserchat(userId, (data: any) => {})
            this.props.deleteLastMesg(userId, (data: any) => {})
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  acceptRequest = ():boolean => {
    this.setState({ requestSendLoader: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token" :Cookies.get('Login_Token')
    };

    const httpBody = {
      "account_id": this.state.receipentId,
      "status": "approved"
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.acceptRequestApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendAcceptReqEndPoints
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
   runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId != null) {
          //CreateAccount response Api
          if (
            apiRequestCallId === this.editCommentApiId &&
            !!responseJson
          ) {
            this.getCommentData(responseJson.data.attributes.post_id)
            this.setState({ editCommentId: 0 })
          }
          if (
            apiRequestCallId === this.deleteCommentApiId &&
            !!responseJson
          ) {
            this.getCommentData(responseJson.data.attributes.post_id)
            this.setState({ deleteCommentId: 0 })
          }
          if (apiRequestCallId === this.getUserIdApiId &&
            responseJson !== undefined
          ) {
            // other api calls which need id parameter
            const id = responseJson?.data?.id
            this.userProfileId = id

            if (id === Cookies.get("Login_Id")) {
              this.props.history.push('/myprofile')
            } else {
              this.getFriendProfile("")
            }
          }
          if (apiRequestCallId === this.acceptRequestApiId &&
            responseJson !== undefined
          ) {
            this.setState({ addFriendBtn: "approved", requestSendLoader: false })
          }
          if (apiRequestCallId === this.getUserNFTsApiId &&
            responseJson !== undefined
          ) {
            this.setState({ ...this.state, userNFTs: responseJson?.data })
          }
          if (
            apiRequestCallId === this.myInfoUpdateApiId &&
            responseJson !== undefined
          ) {
            toast.success('Profile Updated Successfully', { position: toast.POSITION.BOTTOM_RIGHT })
          }
          if (
            apiRequestCallId === this.profilePictureApiCallId &&
            responseJson !== undefined
          ) {
            toast.success('Profile Picture updated Successfully', { position: toast.POSITION.BOTTOM_RIGHT })
            window.location.reload();
          }
          if (
            apiRequestCallId === this.allMyNftPostsapiId &&
            responseJson !== undefined
          ) {

            this.setState({ myAllPost: responseJson.data, postLoader: false })

          }
          if (
            apiRequestCallId === this.searchFriendcallId &&
            responseJson !== undefined
          ) {

            this.setState({ getFriendOfFriend: responseJson.data })
            setTimeout(() => {
              //@ts-ignore
              document.getElementById('searchallFriends').value = this.state.searchedValaddFriend
            }, 1);


          }
          if (
            apiRequestCallId === this.allFirendsApiId &&
            responseJson !== undefined
          ) {
          }
          if (
            apiRequestCallId === this.getFriendOfFriendId &&
            responseJson !== undefined
          ) {
            this.setState({ getFriendOfFriend: responseJson.data })
            setTimeout(() => {
              this.setState({ showBackDrop: false })
            }, 1200);
          }
          if (
            apiRequestCallId === this.unfollowFriendApi &&
            responseJson !== undefined
          ) {

            toast.success("You have unfollowed friend", { position: toast.POSITION.BOTTOM_RIGHT })
            this.setState({ followStatus: true })

          }
          if (
            apiRequestCallId === this.followFriendApi &&
            responseJson !== undefined
          ) {
            toast.success("You have followed friend", { position: toast.POSITION.BOTTOM_RIGHT })
            this.setState({ followStatus: false })
          }
          if (
            apiRequestCallId === this.createCommentApiId &&
            responseJson !== undefined
          ) {
            this.getAllMyNftPost()
            this.getCommentData(responseJson.data.attributes.post_id)
          }
          if (
            apiRequestCallId === this.commentReplyDataApiId &&
            responseJson !== undefined
          ) {
            this.setState({ allRepliedComments: responseJson.data })
          }
          if (
            apiRequestCallId === this.createReplyCommentApiId &&
            responseJson !== undefined
          ) {
            this.getAllMyNftPost()
            this.getCommentData(responseJson.data.attributes.post_id)
            this.getRepliedCommentData(responseJson.data.attributes.comment_id)
          }
          if (
            apiRequestCallId === this.commentDataApiId &&
            responseJson !== undefined
          ) {
            this.setState({ allComments: responseJson.data })
          }
          if (
            apiRequestCallId === this.createLikeonCommentApiId &&
            responseJson !== undefined
          ) {
            this.getCommentData(responseJson.data.attributes.post_id)
            this.getRepliedCommentData(responseJson.data.attributes.comment_id)
          }
          if (
            apiRequestCallId === this.unfriendApiId &&
            responseJson !== undefined
          ) {
            toast.success(responseJson.message, { position: toast.POSITION.BOTTOM_RIGHT })
            this.setState({ connectionId: null })
            this.setState({ addFriendBtn: null })
            this.setState({ showBackDrop: false })
          }
          if (
            apiRequestCallId === this.singleFirendsApiId &&
            responseJson !== undefined
          ) {
            if (!!responseJson.data || responseJson.data?.length > 0) {

              this.setState({ responseLocation: responseJson?.data?.attributes?.city })
              this.setState({ responseInformation: responseJson?.data?.attributes?.about })
              this.setState({ responseEmail: responseJson?.data?.attributes?.email })
              this.setState({ responsePhone: responseJson?.data?.attributes?.mobile_number })
              this.setState({ responseBirthDate: responseJson?.data?.attributes?.date_of_birth })
              this.setState({ profileImage: responseJson?.data?.attributes?.image })
              this.setState({ fullName: responseJson?.data?.attributes?.full_name })
              this.setState({ userName: responseJson?.data?.attributes?.user_name })
              this.setState({ connectionId: responseJson?.data?.attributes?.connection_id })
              this.setState({ receipentId: responseJson?.data?.attributes?.receipient_id })
              this.setState({ requestCreatedBy: responseJson?.data?.attributes?.friend_request_created })
              this.setState({ responseCountry: responseJson?.data?.attributes?.city + ' ' + responseJson?.data?.attributes?.country })
              this.setState({ responseCity: responseJson?.data?.attributes?.city })
              this.setState({ addFriendBtn: responseJson?.data?.attributes?.status })
              this.setState({ accountId: responseJson?.data?.attributes?.account_id })
              this.setState({ isBlocked: responseJson?.data?.attributes?.is_blocked })
              this.setState({ showBackDrop: false })
              this.setState({ followStatus: !responseJson?.data?.attributes?.follow })

              this.getAllMyNftPost()
              this.verifiedNFT()
              this.getFriendsList()
              this.getFriendOfFriend(this.userProfileId)
              this.getUserNFTs()
            } else {
              this.props.history.push({
                pathname: "/404"
              })
            }
          }
          if (
            apiRequestCallId === this.blockApiId &&
            responseJson !== undefined
          ) {

            toast.success("You have blocked " + responseJson.data.attributes.account.full_name, { position: toast.POSITION.BOTTOM_RIGHT })
            this.setState({ isBlocked: true, connectionId: null })
          }
          if (
            apiRequestCallId === this.blockedUserApiId &&
            responseJson !== undefined
          ) {
            toast.success("User unblocked.", { position: toast.POSITION.BOTTOM_RIGHT })
            this.setState({ isBlocked: false, connectionId: null })
          }
          if (
            apiRequestCallId === this.myNftopenSeaApiCallId &&
            responseJson !== undefined
          ) {

            this.setState({
              myNftallAssets: responseJson.assets,
              myNftopenChild: true
            })
          }
          if (
            apiRequestCallId === this.reportReasonsApiId &&
            responseJson !== undefined
          ) {
            this.setState({ ...this.state, reportReasons: responseJson.data })
          }
          if (
            apiRequestCallId === this.postReportApiId &&
            responseJson !== undefined
          ) {
            this.setState({ ...this.state, confirmReportDialog: false })
            toast.success(responseJson?.message, { position: toast.POSITION.BOTTOM_RIGHT })
          }
          if (
            apiRequestCallId === this.deletePostApiId &&
            responseJson !== undefined
          ) {

            this.getAllMyNftPost()
            toast.success(responseJson.message, { position: toast.POSITION.BOTTOM_RIGHT })
          }
          if (
            apiRequestCallId === this.likePostApiId &&
            responseJson !== undefined
          ) {

            //@ts-ignore
            const b = document.querySelector("#likePost");
            //@ts-ignore
            b.setAttribute("style", "color:#3498db")
            this.getAllMyNftPost()
          }
          if (
            apiRequestCallId === this.dislikePostApiId &&
            responseJson !== undefined
          ) {

            //@ts-ignore
            const b = document.querySelector("#likePost");
            //@ts-ignore
            b.setAttribute("style", "color:grey")
            this.getAllMyNftPost()
          }
          if (
            apiRequestCallId === this.myNftcreateWalletAccountApiCallId &&
            responseJson !== undefined
          ) {

            Cookies.set('MetaMask_Owner_Id', responseJson.data.attributes.address)
            this.setState({ myNftmetamaskOwnerId: responseJson.data.attributes.address })
            this.myNftopenseaAccount(responseJson.data.attributes.address)
            this.setState({ myNftmetamaskBTN: true, myNftuploadNFT: true })
          }
          if (
            apiRequestCallId === this.verifiedNFTApiCallId &&
            responseJson !== undefined
          ) {

            this.setState({ myAllNfts: responseJson.verified_nfts })
          }
          if (
            apiRequestCallId === this.myNftcreateNFTAccountApiCallId &&
            responseJson !== undefined
          ) {
            this.setState({
              myNftopen: false
            })
            toast.success('post is uploaded successfully..!', { position: toast.POSITION.BOTTOM_RIGHT })

          }
          if (
            apiRequestCallId === this.friendApiId &&
            responseJson !== undefined
          ) {
            toast.success("Friend request has been sent.", { position: toast.POSITION.BOTTOM_RIGHT })
            this.setState({ requestSendLoader: false, connectionId: responseJson.data.attributes.id, addFriendBtn: "pending", requestCreatedBy: responseJson?.data?.attributes?.account_id })
          }
          if (
            apiRequestCallId === this.myNftapiMetaMaskWalletsId &&
            responseJson !== undefined
          ) {
            //@ts-ignore
            await window.ethereum.enable();
            //@ts-ignore
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            let acc = accounts[0];
            if (responseJson.data) {
              responseJson.data.forEach((element: any) => {
                if (element.attributes.address.toUpperCase() === acc.toUpperCase()) {
                  Cookies.set('MetaMask_Owner_Id', acc)
                  this.myNftopenseaAccount(acc)
                  this.setState({ myNftmetamaskBTN: true, myNftuploadNFT: true })
                  this.setState({ myNftcheckAccount: 1 })
                }
              });
            } else {
              this.metamaskAccount(acc)
              this.setState({ myNftcheckAccount: 1 })
            }
            if (this.state.myNftcheckAccount === 0) {
              this.metamaskAccount(acc)
            }
          }
          if(
            apiRequestCallId === this.cancelRequestApiId &&
            !!responseJson
          ) {
              this.setState({ addFriendBtn: false, requestSendLoader: false })
            }
        }
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
        const errors = responseJson?.errors;
        if (errors) {

          if (apiRequestCallId === this.allMyNftPostsapiId) {
            this.setState({ postLoader: false })
          }
          if (apiRequestCallId === this.friendApiId) {
            this.setState({ requestSendLoader: false })
          }
          if (apiRequestCallId === this.cancelRequestApiId) {
            this.setState({ requestSendLoader: false })
          }
          if (apiRequestCallId === this.acceptRequestApiId) {
            this.setState({ requestSendLoader: false })
          }
          if (apiRequestCallId === this.singleFirendsApiId && errors[0]?.account === "Record not found") {
            await this.deleteChat(this.userProfileId)
            toast.error("User doesn't exist", { position: toast.POSITION.BOTTOM_RIGHT })
            this.props.history.push({
              pathname: "/404"
            })
          }

          if (errors[0].message === "User already blocked.") {
            toast.error(errors[0].message, { position: toast.POSITION.BOTTOM_RIGHT })
          } else if (errors[0].message === 'No result found.') {

            if (!!this.state.searchedValaddFriend) {
              this.setState({ getFriendOfFriend: [] })
              //@ts-ignore
              document.getElementById('searchallFriends').value = this.state.searchedValaddFriend

            }
          } else if (errors[0]?.message === "Not found any user with username.") {
            toast.error(errors[0].message, { position: toast.POSITION.BOTTOM_RIGHT })
            this.props.history.push({
              pathname: "/404"
            })
          }

        }

      }
    }
  }
  // Customizable Area End

  // Customizable Area Start
  getFriendOfFriend = (userId: any): boolean => {

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      "token": Cookies.get("Login_Token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFriendOfFriendId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFriendOfFriendEndPoint + userId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  ImageUploadAPI(values: any) {

    const header = {
      "token": Cookies.get("Login_Token"),
    };
    const attrs = {
      "profile[image_attributes][image]": values
    };
    const formData = new FormData();
    // Update the formData object

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMessage),
      JSON.stringify(attrs)
    );

    this.apiImageUploadId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ImageUploadApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  // Customizable Area End
}
