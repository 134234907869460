import React from "react";

// Customizable Area Start
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import "date-fns";
import userAccountSettingController, { Props, configJSON } from "./userAccountSettingController.web";
import { withRouter } from 'react-router-dom';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles'
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import "@reach/menu-button/styles.css";
import NavbarWeb from "../../TargetedFeed/src/Navbar.web";
import Link from '@material-ui/core/Link';
import Switch, { SwitchProps, SwitchClassKey } from '@material-ui/core/Switch';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Form, Formik } from "formik";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
   MuiPickersUtilsProvider,
   KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ReactSelect from 'react-select'
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Scrollbar } from "react-scrollbars-custom"
import moment from "moment";
//@ts-ignore
import { DELETE_CHATS, USER_LOGOUT, USER_CHANGE, DELETE_FRIENDLIST, GET_USER, SET_PROFILE, SET_NOTIFICATIONS_CONFIGURATIONS } from "../../../web/src/allActions.js";
import { connect } from "react-redux";
// Customizable Area End

// Customizable Area Start
interface Styles extends Partial<Record<SwitchClassKey, string>> {
   focusVisible?: string
 }
 
 interface CustomSwitchProps extends SwitchProps {
   classes: Styles
 }
 // Customizable Area End

class userSetting extends userAccountSettingController {
   constructor(props: Props) {
      super(props);
      // Customizable Area Start
      // Customizable Area End
   }
   render() {
      //@ts-ignore
      const { classes, notificationsConfigurations: {
         emailAcceptReq,
         emailPostReq,
         emailReceiveReq,
         pushAcceptReq,
         pushPostReq,
         pushReceiveReq,
         smsReceiveReq,
         smsAcceptReq,
         smsPostReq,
      }, profileData: {
         userName,
         firstName,
         lastName,
         emailId,
         dateOfBirth,
         phoneNumber,
         countryCode,
         city,
         state,
         country,
      } } = this.props

      // Customizable Area Start
      const {
         countryData,
         stateData,
         cityData,
         isEditFullName,
         isEditUserLink,
         isEditDOB,
         isEditLocation,
         isEditPhone,
         loader,
         isActive,
         isTwoFactAuth } = this.state
      // Customizable Area End

      function TabPanel(props: any) {
         const { children, value, index, ...other } = props;

         return (
            <div {...other}>{value === index && <Box p={6}>{children}</Box>}
            </div>
         );
      }

      // Customizable Area Start
      const IOSSwitch = withStyles((theme: Theme) =>
         createStyles({
            root: {
               width: 38,
               height: 22,
               padding: 0,
               margin: theme.spacing(1),
            },
            switchBase: {
               padding: 1,
               '&$checked': {
                  transform: 'translateX(16px)',
                  color: theme.palette.common.white,
                  '& + $track': {
                     backgroundColor: '#1f7fb6',
                     opacity: 1,
                     border: 'none',
                  },
               },
               '&$focusVisible $thumb': {
                  color: '#1f7fb6',
                  border: '6px solid #fff',
               },
            },
            thumb: {
               width: 20,
               height: 20,
            },
            track: {
               borderRadius: 26 / 2,
               border: `1px solid ${theme.palette.grey[400]}`,
               backgroundColor: theme.palette.grey[50],
               opacity: 1,
               transition: theme.transitions.create(['background-color', 'border']),
            },
            checked: {},
            focusVisible: {},
         }),
      )(({ classes, ...props }: CustomSwitchProps) => {
         return (
            <Switch
               focusVisibleClassName={classes.focusVisible}
               disableRipple
               classes={{
                  root: classes.root,
                  switchBase: classes.switchBase,
                  thumb: classes.thumb,
                  track: classes.track,
                  checked: classes.checked,
               }}
               {...props}
            />
         );
      })
      // Customizable Area End

      return (
         <>
            <NavbarWeb
               //@ts-ignore
               getAllPostAfterUpload={this.getAllPostAfterUpload}
               showNotification={'showNotification'}
            />
            <div className={classes.container}>
               <Box className={classes.tabWrapper}>
                  <div className={classes.leftTabWrapper}>
                     <Scrollbar className={classes.customScroll + " " + classes.sidebarCustomScroll}>
                        <h2 className={classes.leftTitle}>Settings</h2>
                        <Tabs
                           value={this.state.value}
                           orientation="vertical"
                           variant="scrollable"
                           selectionFollowsFocus
                           onChange={(event: any, newValue: number) => {
                              this.setState({
                                 value: newValue,
                              });
                           }}
                           aria-label="Vertical tabs example"
                        >
                           <Tab icon={<InfoOutlinedIcon className={this.state.AccountInfo ? classes.selectedTabIcon : classes.defaultTabIcon} />}
                              className={this.state.AccountInfo && classes.selectedTab}
                              onClick={() => { this.setState({ AccountInfo: true, Notifications: false, Security: false, HelpCenter: false, ContactUs: false }) }}
                              label="Account Info" />
                           <Tab icon={<NotificationsNoneOutlinedIcon className={this.state.Notifications ? classes.selectedTabIcon : classes.defaultTabIcon} />}
                              onClick={() => { this.setState({ AccountInfo: false, Notifications: true, Security: false, HelpCenter: false, ContactUs: false }) }}
                              className={this.state.Notifications && classes.selectedTab}
                              label="Notifications" />
                           <Tab icon={<VpnKeyOutlinedIcon className={this.state.Security ? classes.selectedTabIcon : classes.defaultTabIcon} />}
                              onClick={() => { this.setState({ AccountInfo: false, Notifications: false, Security: true, HelpCenter: false, ContactUs: false }) }}
                              className={this.state.Security && classes.selectedTab}
                              label="Security" />
                           <Tab icon={<HelpOutlineOutlinedIcon className={this.state.HelpCenter ? classes.selectedTabIcon : classes.defaultTabIcon} />}
                              onClick={() => { this.setState({ AccountInfo: false, Notifications: false, Security: false, HelpCenter: true, ContactUs: false }) }}
                              className={this.state.HelpCenter && classes.selectedTab}
                              label="Help center" />
                           <Tab icon={<EmailOutlinedIcon className={this.state.ContactUs ? classes.selectedTabIcon : classes.defaultTabIcon} />}
                              onClick={() => { this.setState({ AccountInfo: false, Notifications: false, Security: false, HelpCenter: false, ContactUs: true }) }}
                              className={this.state.ContactUs && classes.selectedTab}
                              label="Contact Us" />
                           <Tab icon={<PeopleAltOutlinedIcon className={this.state.Logout ? classes.selectedTabIcon : classes.defaultTabIcon} />}
                              className={this.state.Logout && classes.selectedTab}
                              label="Logout" onClick={() => this.setState({ openDialogueBox: true })}
                           />
                        </Tabs>
                     </Scrollbar>
                  </div>
                  <div className={classes.rightTabWrapper}>
                     <Scrollbar className={classes.customScroll + " " + classes.chatCustomScroll}>
                        <TabPanel value={this.state.value} index={0}>
                           {loader && !emailId ? <CircularProgress className={classes.circularLoader} /> : <>
                              <Formik
                                 enableReinitialize={true}
                                 initialValues={{
                                    firstName,
                                    lastName,
                                    userName,
                                    dateOfBirth,
                                    country,
                                    state,
                                    city,
                                    countryCode,
                                    phoneNumber
                                 }}
                                 onSubmit={() => { }}
                              >
                                 {(formik) => (
                                    <Form translate="yes">
                                       <div className={classes.accountInfo}>
                                          <h2>Account Info</h2>
                                          <div className={classes.accoutInfoList}>
                                             <span>Name</span>
                                             {isEditFullName
                                                ? (
                                                   <span className={classes.editField}>
                                                      <div>
                                                         <TextField
                                                            type="text"
                                                            variant="outlined"
                                                            name="firstName"
                                                            placeholder="First name"
                                                            value={formik.values.firstName}
                                                            onChange={formik.handleChange}
                                                            autoComplete="new-password"
                                                         />
                                                         <TextField
                                                            type="text"
                                                            variant="outlined"
                                                            placeholder="Last name"
                                                            name="lastName"
                                                            value={formik.values.lastName}
                                                            onChange={formik.handleChange}
                                                            autoComplete="new-password"
                                                         />
                                                      </div>
                                                      <Link className={classes.editButton} onClick={() => this.updateUserFullName({
                                                         firstName: formik.values.firstName,
                                                         lastName: formik.values.lastName,
                                                      })}>
                                                         Save
                                                      </Link>
                                                   </span>
                                                ) : (
                                                   <>
                                                      <p>{`${firstName} ${lastName}`}</p>
                                                      {this.state.profileUpdateLoader === "name" ?
                                                         <Link className={classes.editButton}>
                                                            <CircularProgress size={20} style={{ color: '#0388c9' }}/>
                                                         </Link>
                                                         : <Link className={classes.editButton} onClick={() => this.setState({
                                                            ...this.state,
                                                            isEditFullName: true
                                                         })}>
                                                            Edit
                                                         </Link>}
                                                   </>
                                                )}
                                          </div>
                                          <div className={classes.accoutInfoList}>
                                             <span>User link</span>
                                             {isEditUserLink ?
                                                (
                                                   <span className={classes.editField}>
                                                      <div className={classes.editLink}>
                                                         <p>{`${this.domainName}/`}</p><TextField
                                                            type="text"
                                                            variant="outlined"
                                                            placeholder="Username"
                                                            name="userName"
                                                            inputRef={this.userNameRef}
                                                            value={formik.values.userName}
                                                            onChange={formik.handleChange}
                                                            autoComplete="off"
                                                         />
                                                      </div>
                                                      {this.state.profileUpdateLoader === "user name" ?
                                                         <Link className={classes.editButton}>
                                                            <CircularProgress size={20} style={{ color: '#0388c9' }} />
                                                         </Link>
                                                         :
                                                         <Link className={classes.editButton} onClick={() => this.updateUserName(formik.values.userName)}>
                                                            Save
                                                         </Link>}
                                                   </span>
                                                ) :
                                                (
                                                   <>
                                                      <p>{userName && `${this.domainName}/${userName}`}</p>
                                                      <Link className={classes.editButton} onClick={() => this.setState({
                                                         ...this.state,
                                                         isEditUserLink: true
                                                      })}>
                                                         Edit
                                                      </Link>
                                                   </>
                                                )}
                                          </div>
                                          <div className={classes.accoutInfoList}>
                                             <span>Email</span>
                                             <p>{emailId}</p>
                                          </div>
                                          <div className={classes.accoutInfoList}>
                                             <span>Date of birth</span>
                                             {isEditDOB ?
                                                (
                                                   <span className={classes.editField}>
                                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                         <KeyboardDatePicker
                                                            value={moment(formik.values.dateOfBirth, "DD-MM-YYYY")}
                                                            name="dateOfBirth"
                                                            placeholder="Date of birth"
                                                            onChange={() => { }}
                                                            format="dd-MM-yyyy"
                                                            onAccept={(date) => formik.setFieldValue("dateOfBirth", date)}
                                                            error={false}
                                                            helperText={null}
                                                            inputVariant="outlined"
                                                            size="small"
                                                            maxDate={new Date()}
                                                            inputProps={{ readOnly: true }}
                                                         />
                                                      </MuiPickersUtilsProvider>
                                                      <Link className={classes.editButton} onClick={() => {
                                                         this.setState({
                                                            ...this.state,
                                                            isEditDOB: false,
                                                            profileUpdateLoader: "dob"
                                                         })
                                                         this.updateInformationAPI(formik.values)
                                                      }}>
                                                         Save
                                                      </Link>
                                                   </span>) : (
                                                   <>
                                                      <p>{dateOfBirth}</p>
                                                      {this.state.profileUpdateLoader === "dob" ?
                                                         <Link className={classes.editButton}>
                                                            <CircularProgress size={20} style={{ color: '#0388c9' }} />
                                                         </Link> : 
                                                      <Link className={classes.editButton} onClick={() => this.setState({
                                                         ...this.state,
                                                         isEditDOB: true
                                                      })}>
                                                         Edit
                                                      </Link>}
                                                   </>)}
                                          </div>
                                          <div className={classes.accoutInfoList}>
                                             <span>Location</span>
                                             <div className={classes.editField}>
                                                <div className={classes.locationOption}>
                                                   <FormControl variant="filled"><span>Country</span>
                                                      <ReactSelect
                                                         value={countryData.find((option: any) => option.iso2 === formik.values.country) || ''}
                                                         className={classes.accoutInfoSelect}
                                                         onChange={(e: any) => {
                                                            formik.setFieldValue("country", e.iso2)
                                                            this.props.setProfile({
                                                               country: e.iso2,
                                                               state: "",
                                                               city: ""
                                                            })
                                                         }}
                                                         options={countryData}
                                                         isSearchable
                                                         placeholder="Select country"
                                                         isDisabled={!isEditLocation}
                                                         getOptionLabel={(country: any) => country.name}
                                                         getOptionValue={(country: any) => country.iso2}
                                                      />
                                                   </FormControl>
                                                </div>
                                                <div className={classes.locationOption}>
                                                   <FormControl variant="filled"><span>State</span>
                                                      <ReactSelect
                                                         value={stateData.find((option: any) => option.iso2 === formik.values.state) || ''}
                                                         className={classes.accoutInfoSelect}
                                                         onChange={(e: any) => {
                                                            formik.setFieldValue("state", e.iso2)
                                                            this.props.setProfile({
                                                               state: e.iso2,
                                                               city: ""
                                                            })
                                                         }}
                                                         options={stateData}
                                                         isSearchable
                                                         placeholder="Select state"
                                                         isDisabled={!isEditLocation}
                                                         getOptionLabel={(state: any) => state.name}
                                                         getOptionValue={(state: any) => state.iso2}
                                                      />
                                                   </FormControl>
                                                   <FormControl variant="filled"><span>City</span>
                                                      <ReactSelect
                                                         value={cityData.find((option: any) => option.name === formik.values.city) || ''}
                                                         onChange={(e: any) => formik.setFieldValue("city", e.name)}
                                                         className={classes.accoutInfoSelect}
                                                         options={cityData}
                                                         isSearchable
                                                         placeholder="Select a city"
                                                         isDisabled={!isEditLocation}
                                                         getOptionLabel={(city: any) => city.name}
                                                         getOptionValue={(city: any) => city.name}
                                                      />
                                                   </FormControl>
                                                </div>
                                                {isEditLocation ?
                                                   (
                                                      <Link className={classes.editButton} onClick={() => {
                                                         this.setState({
                                                            ...this.state,
                                                            isEditLocation: false,
                                                            profileUpdateLoader: "location"
                                                         })
                                                         this.updateInformationAPI(formik.values)
                                                      }}>
                                                         Save
                                                      </Link>
                                                   ) : (
                                                      this.state.profileUpdateLoader === "location" ?
                                                         <Link className={classes.editButton}>
                                                            <CircularProgress size={20} style={{ color: '#0388c9' }} />
                                                         </Link> :
                                                         <Link className={classes.editButton} onClick={() => this.setState({
                                                            ...this.state,
                                                            isEditLocation: true
                                                         })}>
                                                            Edit
                                                         </Link>
                                                   )
                                                }
                                             </div>
                                          </div>
                                          <div className={classes.accoutInfoList}>
                                             <span>Phone</span>
                                             {isEditPhone ?
                                                (
                                                   <span className={classes.editField}>
                                                      <div className={classes.locationOption}>
                                                         <FormControl variant="filled">
                                                            <ReactSelect
                                                               className={classes.accoutInfoSelect}
                                                               value={configJSON.countryCodes.find((option: any) => option.dial_code === formik.values.countryCode) || ''}
                                                               onChange={(e: any) => formik.setFieldValue("countryCode", e.dial_code)}
                                                               options={configJSON.countryCodes}
                                                               isSearchable
                                                               placeholder="Country code"
                                                               getOptionLabel={(country: any) => `(${country.dial_code}) ${country.name}`}
                                                               getOptionValue={(country: any) => country.dial_code}
                                                            />
                                                         </FormControl>
                                                         <TextField
                                                            variant="outlined"
                                                            placeholder="Phone number"
                                                            name="phoneNumber"
                                                            defaultValue={formik.values.phoneNumber}
                                                            onChange={formik.handleChange}
                                                            autoComplete="off"
                                                            inputProps={{ maxLength: 10 }}
                                                         />
                                                      </div>
                                                      <Link className={classes.editButton} onClick={() => {
                                                         this.setState({
                                                            ...this.state,
                                                            isEditPhone: false,
                                                            profileUpdateLoader: "phone number"
                                                         })
                                                         this.updateInformationAPI(formik.values)
                                                      }}>
                                                         Save
                                                      </Link>
                                                   </span>
                                                ) : (
                                                   <>
                                                      <p>{`${countryCode || ""} ${phoneNumber || ""}`}</p>
                                                      {this.state.profileUpdateLoader === "phone number" ?
                                                         <Link className={classes.editButton}>
                                                            <CircularProgress size={20} style={{ color: '#0388c9' }} />
                                                         </Link> :
                                                         <Link className={classes.editButton} onClick={() => this.setState({
                                                            ...this.state,
                                                            isEditPhone: true
                                                         })}>
                                                            Edit
                                                         </Link>}
                                                   </>
                                                )
                                             }
                                          </div>
                                          <div className={classes.accoutInfoList + " " + classes.alignCenter}>
                                             <span>Deactivate account</span>
                                             <p>Active account</p>
                                             <IOSSwitch checked={isActive} onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setState({
                                                ...this.state,
                                                isActive: event?.target?.checked,
                                                openActiveBox: !event?.target?.checked
                                             })} />
                                          </div>
                                       </div>
                                    </Form>)}
                              </Formik>
                           </>}
                        </TabPanel>
                        <TabPanel value={this.state.value} index={1}>
                           <div className={classes.accountInfo}>
                              <h2>Notifications</h2>
                              <div className={classes.accoutInfoList}>
                                 <span>Email notifications</span>
                                 <div className={classes.linkWallet}>
                                    <div className={`${classes.linkWalletItem} ${classes.alignCenter}`}>
                                       <p>Comments or replies on my post</p>
                                       <IOSSwitch id="notify_post_comment_reply" checked={emailPostReq} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                          this.props.setNotificationsConfigurations({ emailPostReq: e.target.checked })
                                          this.updatePushAndEmailNotification('emailPostReq')
                                       }} />
                                    </div>
                                    <div className={`${classes.linkWalletItem} ${classes.alignCenter}`}>
                                       <p>I received a friend request</p>
                                       <IOSSwitch id="notify_receive_request" checked={emailReceiveReq} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                          this.props.setNotificationsConfigurations({ emailReceiveReq: e.target.checked })
                                          this.updatePushAndEmailNotification('emailReceiveReq')
                                       }} />
                                    </div>
                                    <div className={`${classes.linkWalletItem} ${classes.alignCenter}`}>
                                       <p>Someone accepted my friend request</p>
                                       <IOSSwitch id="notify_accept_request" checked={emailAcceptReq}
                                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                             this.props.setNotificationsConfigurations({ emailAcceptReq: e.target.checked })
                                             this.updatePushAndEmailNotification('emailAcceptReq')
                                          }} />
                                    </div>
                                 </div>
                              </div>
                              <div className={classes.accoutInfoList}>
                                 <span>Web push notifications</span>
                                 <div className={classes.linkWallet}>
                                    <div className={`${classes.linkWalletItem} ${classes.alignCenter}`}>
                                       <p>Comments or replies on my post</p>
                                       <IOSSwitch id="push_post_comment_reply" checked={pushPostReq} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                          this.props.setNotificationsConfigurations({ pushPostReq: e.target.checked })
                                          this.updatePushAndEmailNotification('pushPostReq')
                                       }} />
                                    </div>
                                    <div className={`${classes.linkWalletItem} ${classes.alignCenter}`}>
                                       <p>I received a friend request</p>
                                       <IOSSwitch id="push_receive_request" checked={pushReceiveReq} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                          this.props.setNotificationsConfigurations({ pushReceiveReq: e.target.checked })
                                          this.updatePushAndEmailNotification('pushReceiveReq')
                                       }} />
                                    </div>
                                    <div className={`${classes.linkWalletItem} ${classes.alignCenter}`}>
                                       <p>Someone accepted my friend request</p>
                                       <IOSSwitch id="push_accept_request" checked={pushAcceptReq} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                          this.props.setNotificationsConfigurations({ pushAcceptReq: e.target.checked })
                                          this.updatePushAndEmailNotification('pushAcceptReq')
                                       }} />
                                    </div>
                                 </div>
                              </div>
                              <div className={classes.accoutInfoList}>
                                 <span>SMS(Text) notifications</span>
                                 <div className={classes.linkWallet}>
                                    <div className={`${classes.linkWalletItem} ${classes.alignCenter}`}>
                                       <p>Comments or replies on my post</p>
                                       <IOSSwitch
                                          checked={smsPostReq}
                                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                             this.props.setNotificationsConfigurations({ smsPostReq: e.target.checked })
                                             this.updatePushAndEmailNotification('smsPostReq')
                                          }} />
                                    </div>
                                    <div className={`${classes.linkWalletItem} ${classes.alignCenter}`}>
                                       <p>I received a friend request</p>
                                       <IOSSwitch
                                          checked={smsReceiveReq}
                                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                             this.props.setNotificationsConfigurations({ smsReceiveReq: e.target.checked })
                                             this.updatePushAndEmailNotification('smsReceiveReq')
                                          }} />
                                    </div>
                                    <div className={`${classes.linkWalletItem} ${classes.alignCenter}`}>
                                       <p>Someone accepted my friend request</p>
                                       <IOSSwitch
                                          checked={smsAcceptReq}
                                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                             this.props.setNotificationsConfigurations({ smsAcceptReq: e.target.checked })
                                             this.updatePushAndEmailNotification('smsAcceptReq')
                                          }} />
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </TabPanel>
                        <TabPanel value={this.state.value} index={2}>
                           <Formik
                              initialValues={{
                                 current_password: "",
                                 new_password: "",
                                 confirm_password: ""
                              }}
                              validationSchema={this.fAQSchema}

                              onSubmit={(values, { resetForm }): any => { this.resetPasswordRequest(values, this.state.userIdResetPassword); resetForm(); }}
                           >
                              {(formik) => (
                                 //@ts-ignore
                                 <Form className={classes.formWrap} style={{ background: "#ffffff", width: "80%", padding: "30px 30px 15px", margin: "0 auto" }}>
                                    <div className={classes.accountInfo}>
                                       <h2>Password</h2>
                                       <div className={classes.accoutInfoList + " " + classes.noBorder + " " + classes.alignCenter}>
                                          <span>Current Password</span>
                                          <TextField
                                             id="current_password"
                                             fullWidth
                                             variant="standard"
                                             placeholder="Current password"
                                             value={formik.values.current_password}
                                             onChange={formik.handleChange}
                                             onBlur={formik.handleBlur}
                                             name="current_password"
                                             type="password"
                                             error={(formik.touched.current_password && formik.errors.current_password) ? true : false}
                                             helperText={(formik.touched.current_password && formik.errors.current_password) && formik.errors.current_password}
                                          />
                                       </div>
                                       <div className={classes.accoutInfoList + " " + classes.noBorder + " " + classes.alignCenter}>
                                          <span>New Password</span>
                                          <TextField
                                             id="new_password"
                                             fullWidth
                                             variant="standard"
                                             placeholder="New password"
                                             value={formik.values.new_password}
                                             onChange={formik.handleChange}
                                             onBlur={formik.handleBlur}
                                             name="new_password"
                                             type="password"
                                             error={(formik.touched.new_password && formik.errors.new_password) ? true : false}
                                             helperText={(formik.touched.new_password && formik.errors.new_password) && formik.errors.new_password?.replace("new_password", "Password")}
                                          />
                                       </div>
                                       <div className={classes.accoutInfoList + " " + classes.noBorder + " " + classes.alignCenter}>
                                          <span>Confirm Password</span>
                                          <TextField
                                             id="confirm_password"
                                             fullWidth
                                             variant="standard"
                                             placeholder="Confirm password"
                                             value={formik.values.confirm_password}
                                             onChange={formik.handleChange}
                                             onBlur={formik.handleBlur}
                                             name="confirm_password"
                                             type="password"
                                             error={(formik.touched.confirm_password && formik.errors.confirm_password) ? true : false}
                                             helperText={(formik.touched.confirm_password && formik.errors.confirm_password) && formik.errors.confirm_password}
                                          />
                                       </div>
                                       <div className={classes.accoutInfoList + " " + classes.alignLeft}>
                                          <span></span>
                                          <Button
                                             variant="contained"
                                             type="button"
                                             className={classes.submitBtn}
                                             disabled={this.state.passowordChangeLoader}
                                             onClick={(e) => formik.handleSubmit()}
                                          >
                                             {this.state.passowordChangeLoader ? <CircularProgress size={20} style={{ color: '#ffffff', marginTop: '6px' }}/>: 'Change' }
                                          </Button>
                                       </div>
                                       <div className={classes.accoutInfoList + " " + classes.noBorder + " " + classes.alignCenter}>
                                          <span>Two-factor authentication</span>
                                          <IOSSwitch checked={isTwoFactAuth} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                             this.setState({ ...this.state, isTwoFactAuth: event.target.checked, loader: true })
                                             this.handleTwoFactorAuthentication(event.target.checked)
                                          }} />
                                       </div>
                                       <div className={classes.authantication}>
                                          <p>Protect your deegee account <a href="mailto:jonhdoesmith@mail.com">{emailId}</a>  with two-factor autentication via email.</p>
                                          <p>Once enabled, then the next time you log in, you are asked to click the verification link in an email to access your account. You only need to verify yourself every 30 days on each device.</p>
                                       </div>
                                    </div>
                                 </Form>
                              )}
                           </Formik>
                        </TabPanel>
                        <TabPanel value={this.state.value} index={3}>
                           <div className={classes.accountInfo}>
                              <h2>Help center</h2>
                              <div className={classes.helpCenterTab}>
                                 <h2>How can we help you?</h2>
                                 <TextField
                                    className={classes.searchBar}
                                    variant="outlined"
                                    InputProps={{
                                       startAdornment: (
                                          <InputAdornment position="start">
                                             <SearchIcon />
                                          </InputAdornment>
                                       ),
                                    }}
                                    id="searchHelpCenter"
                                    placeholder="Search FAQs"
                                    onInput={(event: any) => {
                                       this.handleChangeDebounce(event.target.value)
                                    }}

                                 />
                                 <div className={classes.faqContent}>
                                    <h3>
                                       FAQ's
                                    </h3>
                                    <div className={classes.chatAccordion}>
                                       {this.state.FaQList.map((question: any, index: any) => {
                                          return (
                                             <Accordion key={index}>
                                                <AccordionSummary
                                                   expandIcon={<ExpandMoreIcon />}
                                                   aria-controls="panel1a-content"
                                                   id="chatUser-1"
                                                >
                                                   <div className={classes.accordionUser}>
                                                      <div className={classes.userInfo}>
                                                         <Typography>{question.attributes.question}</Typography>
                                                      </div>
                                                   </div>
                                                </AccordionSummary >
                                                <AccordionDetails className={classes.faqAnswer}>
                                                   {question.attributes.answer}
                                                </AccordionDetails>
                                             </Accordion>
                                          );
                                       })}
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </TabPanel>
                        <TabPanel value={this.state.value} index={4}>
                           <Formik
                              //enableReinitialize={true}
                              // validateOnChange={true}
                              initialValues={{
                                 subject: "",
                                 message: ""
                              }}
                              // validationSchema={this.SaveSchema}

                              onSubmit={(values, { resetForm }): any => {
                                 this.contactUsRequest(values)
                                 resetForm()
                              }}
                           >
                              {(formik) => (
                                 //@ts-ignore
                                 <Form className={classes.formWrap} style={{ background: "#ffffff", width: "80%", padding: "30px 30px 15px", margin: "0 auto" }}>
                                    <div className={classes.accountInfo}>
                                       <h2>Contact Us</h2>
                                       <div className={classes.contactTab}>
                                          <div className={classes.contactText}>
                                             <span>Subject</span>
                                             <TextField
                                                id="subject"
                                                fullWidth
                                                variant="standard"
                                                placeholder="Subject"
                                                value={formik.values.subject}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                name="subject"
                                             //helperText={formik.errors.subject}
                                             />
                                          </div>
                                          <div className={classes.contactText}>
                                             <span>Message</span>
                                             <TextField
                                                id="message"
                                                fullWidth
                                                multiline
                                                rows={8}
                                                variant="standard"
                                                placeholder="Message"
                                                value={formik.values.message}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                name="message"
                                             />
                                          </div>
                                          <Button
                                             variant="contained"
                                             type="button"
                                             className={classes.submitBtn}
                                             onClick={(e) => { formik.handleSubmit(); }}
                                             style={{ width: "100%" }}>
                                             Submit
                                          </Button>
                                       </div>
                                    </div>
                                 </Form>
                              )}
                           </Formik>
                        </TabPanel>
                        <TabPanel value={this.state.value} index={5}>
                        </TabPanel>
                        <Dialog className={classes.logoutModal}
                           open={this.state.openDialogueBox}
                           onClose={() => this.setState({ openDialogueBox: false })}
                           aria-labelledby="alert-dialog-title"
                           aria-describedby="alert-dialog-description"
                        >
                           <DialogTitle id="alert-dialog-title">
                              {"Do you really want to Logout ?"}
                           </DialogTitle>
                           <DialogActions>
                              <Button disabled={this.state.logoutLoader} className={classes.agreebtn} onClick={() => this.setState({ openDialogueBox: false })}>Cancel</Button>
                              <Button className={classes.disagreebtn} onClick={this.handleLogout} autoFocus>
                                 {this.state.logoutLoader ? <CircularProgress size={23} style={{ color: '#ffffff' }} /> : "Logout"}
                              </Button>
                           </DialogActions>
                        </Dialog>
                        <Dialog
                           open={this.state.openActiveBox}
                           onClose={() => this.setState({ openActiveBox: false })}
                           aria-labelledby="deactivation-dialog"
                           className={classes.deactivateDialog}
                        >
                           <DialogTitle className={classes.titleDialog} id="deactivation-dialog">
                              Deactivate account permanently
                              <IconButton>
                                 <CloseIcon onClick={this.cancelDeactivation} />
                              </IconButton>
                           </DialogTitle>
                           <DialogContent className={classes.txtDialog}>
                              <p>Confirm if you want to deactivate and delete your deegee account permanently.</p>
                              <p>
                                 <span><b>NOTE:</b></span>
                                 Once the deactivation and deletion process has started, you will not be able to reactivate your account or recover the content or information you added.
                              </p>
                              <p className={classes.centerTxt}>
                                 Please confirm if you want to deactivate and delete your deegee account permanently.
                              </p>
                           </DialogContent>
                           <DialogActions style={{ paddingBottom: '15px' }}>
                              <Button className={classes.btnAddFriend} onClick={this.cancelDeactivation} disabled={loader} >Cancel</Button>
                              <Button className={classes.btnChat} disabled={loader} onClick={this.handleDeactivation}>
                              {loader ? <CircularProgress style={{ color: '#ffffff' }} size={20} /> : 'Deactivate and delete account'}
                              </Button>
                           </DialogActions>
                        </Dialog>
                     </Scrollbar>
                  </div>
               </Box>
            </div>
         </>
      );
   }
}

// Customizable Area Start
const useStyles = {
   "& .css-view-1dbjc4n": {
      width: "auto",
      height: "auto",
   },
   sidebarCustomScroll: {
      height: "calc(100vh - 145px) !important",
   },
   chatCustomScroll: {
      height: "calc(100vh - 105px) !important",
   },
   customScroll: {
      "& .ScrollbarsCustom-Track": {
         width: "4px !important",
         background: "transparent !important"
      }
   },
   defaultTabIcon: {
      backgroundColor: '#ebecec!important',
      color: "#1f7fb6!important"
   },
   selectedTabIcon: {
      backgroundColor: '#1f7fb6!important',
      color: "#ebecec!important"
   },
   selectedTab: {
      backgroundColor: '#ebecec'
   },
   formWrap: {
      "@media(max-width: 1199px)": {
         width: "100% !important",
      },
   },
   locationOption: {
      flex: "0 0 calc(100% - 60px)",
      maxWidth: "calc(100% - 60px)",
      padding: "5px 0",
      "@media(max-width: 1199px)": {
         padding: "0",
      },
      "& span": {
         fontSize: "15px",
         color: "#565a57",
         fontWeight: "400",
         fontFamily: "'Roboto', sans-serif",
         minWidth: "76px !important",
         display: "inline-block",
         textAlign: "right !important",
         paddingRight: "10px"
      },
      "& .MuiFormControl-root": {
         display: "inline-flex",
         flexDirection: "inherit",
         alignItems: "center",
         paddingRight: "10px",
         "@media(max-width: 1199px)": {
            paddingBottom: "5px",
            paddingTop: "5px",
         },
      },
      "& .MuiSelect-root": {
         padding: "11px 30px 10px 10px"
      },
   },
   accoutInfoSelect: {
      background: "#ebecec",
      borderRadius: "8px",
      minWidth: "160px",
      fontSize: "15px",
      color: "#565a57",
      fontWeight: "400",
      fontFamily: "'Roboto', sans-serif",
      "& .css-1okebmr-indicatorSeparator":{
         display: "none"
      },
      "& .css-109onse-indicatorSeparator":{
         display: "none"
      }
   },
   deactivateDialog: {
      "& .MuiPaper-root": {
         width: "100%",
      },
      "& *": {
         boxSizing: "border-box"
      },
      "&.MuiDialogContent-root": {
         padding: "8px 34px",
      }
   },
   logoutModal: {
      "& h2": {
         fontSize: "18px",
         color: "#000000",
         fontWeight: "500",
         textAlign: "center",
         padding: "10px",
      },
      "& .MuiButton-text": {
         minWidth: "110px"
      },
      "& .MuiDialogTitle-root": {
         padding: "5px 15px 0"
      },
      "& .MuiDialogActions-root": {
         padding: "5px 15px 15px",
         justifyContent: "center",
      }
   },
   agreebtn: {
      border: "1px solid #1f7fb6",
      backgroundColor: "#e4e5e4",
      height: "40px",
      borderRadius: "50px",
      boxShadow: "none !important",
      display: "inline-flex",
      alignItems: "center",
      "& .MuiButton-label": {
         fontSize: "16px !important",
         fontWeight: "700 !important",
         color: "#1f7fb6 !important",
         margin: "0 !important",
         textTransform: "none",
      },
      "& svg": {
         marginLeft: "5px",
         height: "23px"
      }
   },
   disagreebtn: {
      border: "1px solid #1f7fb6",
      backgroundColor: "#1f7fb6 !important",
      height: "40px",
      borderRadius: "50px",
      boxShadow: "none !important",
      display: "inline-flex",
      alignItems: "center",
      "& .MuiButton-label": {
         fontSize: "16px !important",
         fontWeight: "700 !important",
         color: "#ffffff !important",
         margin: "0 !important",
         textTransform: "none",
      },
      "& svg": {
         height: "23px"
      }
   },
   txtDialog: {
      "& p": {
         fontSize: "15px",
         color: "#505452",
         fontWeight: "400",
         fontFamily: "'Roboto', sans-serif",
         margin: "0",
         paddingBottom: "20px",
         lineHeight: "1.3",
         "& span": {
            width: "100%",
            display: "block"
         },
      }
   },
   centerTxt: {
      fontSize: "18px !important",
      textAlign: "center",
      fontWeight: "700 !important",
   },
   btnChat: {
      margin: "0 4px",
      minWidth: "91px",
      backgroundColor: "#1f7fb6",
      height: "41px",
      fontSize: "16px",
      color: "#ffffff",
      fontWeight: "700",
      letterSpacing: "0",
      border: "1px solid #1f7fb6",
      borderRadius: "5px",
      textAlign: "center",
      textTransform: "none",
      "&:hover": {
         backgroundColor: "#1f7fb6",
      },
      "& .MuiSvgIcon-root": {
         marginRight: "5px",
      },
   },
   titleDialog: {
      "& h2": {
         display: "flex",
         justifyContent: "space-between",
         fontSize: "21px",
         color: "#000105",
         fontWeight: "600",
         fontFamily: "'Poppins', sans-serif",
         padding: "0 0 10px",
         width: "100%",
         borderBottom: "2px solid #d8dddd",
         boxSizing: "border-box",
      },
      "& .MuiIconButton-root": {
         padding: "0",
         width: "35px",
         height: "35px",
      }
   },
   helpCenterTab: {
      "& h2": {
         borderBottom: "none !important",
         padding: "20px 15px!important"
      }
   },
   faqContent: {
      padding: "0 15px",
      "& h3": {
         fontSize: "18px",
         color: "#000105",
         fontWeight: "400",
         margin: "0",
         lineHeight: "1.3",
         fontFamily: "'Poppins', sans-serif",
         padding: "20px 0",
      }
   },

   contactText: {
      paddingBottom: "10px",
      "& span": {
         fontSize: "18px",
         color: "#4d4e4c",
         fontWeight: "400",
         margin: "0",
         lineHeight: "1.3",
         fontFamily: "'Poppins', sans-serif",
         padding: "10px 0",
         display: "block"
      }
   },
   searchBar: {
      padding: "2px 15px",
      position: "relative",
      width: "100%",
      "&::before": {
         position: "absolute",
         content: "",
         height: "10px",
         width: "2px",
         backgroundColor: "#6e7370",
         left: "0px"
      },
      "& .MuiInputBase-input": {
         padding: "0 10px",
         margin: "13px 0",
         borderLeft: "1px solid #6e7370"
      },
      "& .MuiInputBase-root": {
         backgroundColor: "#dcdcd7",
         color: "#6e7370",
         borderRadius: "30px",
         margin: "10px 0",
         cursor: "pointer",
      },
      "& .MuiOutlinedInput-notchedOutline": {
         display: "none",
      }
   },
   container: {
      background: "#FFFFFF",
      padding: "0 20px",
      overflow: "hidden",
      height: "100vh",
      "& *": {
         boxSizing: "border-box",
      },
   },
   tabWrapper: {
      display: "flex",
   },
   alignCenter: {
      alignItems: "center",
      padding: "10px 15px !important",
   },
   alignLeft: {
      justifyContent: "flex-start !important",
   },
   leftTabWrapper: {
      flex: "0 0 347px",
      maxWidth: "347px",
      backgroundColor: "#fffffe",
      borderRadius: "10px",
      minHeight: "calc(100vh - 115px)",
      padding: "20px",
      height: "100%",
      border: "1px solid #ebecec",
      overflow: "auto",
      maxHeight: "calc(100vh - 100px)",
      "& .MuiTabs-indicator": {
         display: "none",
      },
      "& .MuiTab-labelIcon": {
         width: "100%",
         opacity: "1",
         maxWidth: "100%",
         borderRadius: "5px",
         "& .MuiTab-wrapper": {
            flexDirection: "row",
            justifyContent: "flex-start",
            fontSize: "16px",
            color: "#000105",
            fontWeight: "700",
            textTransform: "none",
            fontFamily: "'Poppins', sans-serif",
            margin: "0",
            "& svg": {
               margin: "0",
               width: "55px",
               height: "55px",
               padding: "8px",
               boxSizing: "border-box",
               backgroundColor: "#1f7fb6",
               color: "#ffffff",
               fontWeight: "500",
               borderRadius: "50%",
               marginRight: "13px",
            },
         },
      },
   },
   datePicker: {
      width: '220px'
   },
   chatAccordion: {
      paddingTop: "8px",
      "& .MuiButtonBase-root": {
         backgroundColor: "#ebecec"
      },
      "& .MuiAccordionSummary-content": {
         margin: "0",
      },
      "& .MuiAccordionSummary-root": {
         padding: "12px",
         minHeight: "initial",
         "&.Mui-expanded": {
            backgroundColor: "#deedf4",
         }
      },
      "& .MuiPaper-root": {
         boxShadow: "none",
         borderRadius: "5px",
         overflow: "hidden",
         "&.Mui-expanded": {
            margin: "0"
         }
      },
      "& .MuiAccordionSummary-expandIcon": {
         padding: "0 10px",
         "& .MuiSvgIcon-root": {
            fontSize: "24px",
            color: "#1f7fb6"
         }
      },
      "& .MuiCollapse-root": {
         border: "1px solid #dcdcd7",
         borderRadius: "0 0 5px 5px",
      },
      "& .MuiAccordionDetails-root": {
         position: "relative",
         display: "block",
         "& p": {
            width: "100%",
            display: "block",
            fontSize: "18px",
            color: "#000105",
            fontWeight: "400",
            margin: "0",
            lineHeight: "1.3",
            paddingBottom: "10px",
            textAlign: "left",
            fontFamily: "'Roboto', sans-serif",
         }
      },
      "& .MuiInputBase-input": {
         padding: "11px 15px",
         boxSizing: "border-box",
         backgroundColor: "#ebecec",
         color: "#6e7370",
         fontSize: "14px",
         borderRadius: "30px",
         margin: "0px",
         width: "100%",
         cursor: "pointer",
         height: "40px",
         overflow: "hidden"
      },
      "& .MuiInputBase-root": {
         background: "transparent",
         padding: "0",
         "&:before": {
            display: "none"
         },
         "&:after": {
            display: "none"
         }
      },
      "& .MuiGrid-item": {
         maxHeight: "280px",
         paddingBottom: "10px !important"
      },
      "& .MuiContainer-root": {
         padding: "0",
      }
   },
   accordionUser: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
   },
   userInfo: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      "& .MuiButton-text": {
         width: "100%",
      },
      "& .MuiButton-label": {
         textAlign: "left",
         display: "block",
      },
      "& .MuiAvatar-root": {
         width: "56px",
         height: "56px",
         backgroundColor: "#bcbeba",
         borderRadius: "50%",
         marginRight: "10px",
         "@media(max-width: 1199px)": {
            width: "40px",
            height: "40px",
          }
      },
      "& .MuiTypography-root": {
         textTransform: "none",
         fontSize: "16px",
         color: "#000105",
         fontWeight: "700",
         textAlign: "left",
         fontFamily: "'Roboto', sans-serif",
      },
   },
   faqAnswer: {
      fontFamily: "'Roboto', sans-serif",
   },
   submitBtn: {
      textTransform: "none",
      display: "block",
      backgroundColor: "#1f7fb6",
      border: "2px solid #1f7fb6",
      borderRadius: "55px",
      fontWeight: 700,
      minWidth: '15%',
      "&:hover": {
         backgroundColor: "#1f7fb6",
      },
      "& span": {
         textAlign: "center !important",
         color: "#ffffff !important",
         fontSize: "18px"
      }
   },
   rightTabWrapper: {
      flex: "0 0 calc(100% - 347px)",
      maxWidth: "calc(100% - 347px)",
      padding: "0 15px",
      maxHeight: "calc(100vh - 100px)",
      overflow: "auto",
      "& .MuiBox-root": {
         padding: "0"
      }
   },
   leftTitle: {
      fontSize: "18px",
      fontWeight: "700",
      lineHeight: "1",
      fontFamily: "'Roboto', sans-serif",
      color: "#000307",
      margin: "0",
      paddingBottom: "10px",
      borderBottom: "1px solid #dcdcd7",
   },
   accountInfo: {
      paddingTop: "20px",
      maxWidth: "100%",
      margin: "0 auto",
      "& h2": {
         fontSize: "21px",
         fontWeight: "700",
         lineHeight: "1",
         fontFamily: "'Poppins', sans-serif",
         color: "#000307",
         margin: "0",
         padding: "0 15px 20px",
         borderBottom: "1px solid #d8dddd",
      }
   },
   linkWallet: {
      width: "100%"
   },
   linkWalletItem: {
      display: "flex",
      justifyContent: "space-between",
      borderBottom: "1px solid #d8dddd",
      padding: "0 0 15px 15px",
      marginBottom: "0",
      width: "100%",
      "&:last-child": {
         marginBottom: "0",
         padding: "0 0 0 15px",
         border: "none",
      }
   },
   authantication: {
      padding: "0 20px 20px",
      borderBottom: "1px solid #d8dddd",
      "& p": {
         fontSize: "15px",
         fontWeight: "500",
         lineHeight: "1.3",
         fontFamily: "'Roboto', sans-serif",
         color: "#000105",
         margin: "0",
         textAlign: "left",
         "& a": {
            color: "#000105",
            fontWeight: "700"
         }
      }
   },
   editLink: {
      display: "flex",
      alignItems: "center",
      justifyConteent: "flex-start",
      "& p": {
         width: "auto !important",
         paddingRight: "5px",
      },
      "& .MuiTextField-root": {}
   },
   accoutInfoList: {
      display: "flex",
      alignItems: 'center',
      padding: "20px",
      marginRight: "10px",
      borderBottom: "1px solid #d8dddd",
      "& >span": {
         fontSize: "16px",
         fontWeight: "700",
         lineHeight: "1",
         fontFamily: "'Roboto', sans-serif",
         color: "#000307",
         textAlign: "left",
         minWidth: "200px"
      },
      "& p": {
         fontSize: "16px",
         fontWeight: "500",
         lineHeight: "1",
         fontFamily: "'Roboto', sans-serif",
         color: "#777877",
         margin: "0",
         textAlign: "left",
         width: "100%",
      },
      "& a": {
         fontSize: "15px",
         fontWeight: "500",
         lineHeight: "1",
         fontFamily: "'Roboto', sans-serif",
         color: "#1f7fb6",
         margin: "0",
         textDecoration: "none",
         minWidth: "50px",
         textAlign: "left",
         cursor: 'pointer',
      },

      "& .MuiSwitch-root": {
         minWidth: "auto",
         "& span": {
            minWidth: "auto",
         },
         "& .Mui-checked": {
            color: "#1f7fb6",
            "&+ .MuiSwitch-track": {
               backgroundColor: "#1f7fb6",
               opacity: "1",
            }
         },
         "& .MuiSwitch-track": {
            opacity: "1",
            backgroundColor: "#cccccc"
         },
         "& .MuiSwitch-thumb": {
            color: "#ffffff",
         }
      },
      "& .MuiInputBase-root": {
         "&:after": {
            display: "none",
         },
         "&:before": {
            display: "none",
         },
         "& input": {
            padding: "10px 13px",
            height: "40px",
            background: "#ebecec",
            borderRadius: "8px",
            minWidth: "180px",
            marginRight: "10px"
         },
         "& .MuiOutlinedInput-notchedOutline": {
            display: "none"
         }
      },
   },
   editField: {
      display: 'flex',
      justifyContent: "space-between",
      width: '100%',
      alignItems: 'center',
      flexWrap: "wrap"
   },
   contactTab: {
      padding: "20px 15px",
      maxWidth: "500px",
      "& p": {
         fontSize: "15px",
         color: "#6e7370",
         fontWeight: "400",
         margin: "0",
         lineHeight: "1.3",
         fontFamily: "'Roboto', sans-serif",
         padding: "20px 0",
         display: "block"
      },
      "& .MuiInputBase-root": {
         "&:after": {
            display: "none",
         },
         "&:before": {
            display: "none",
         },
         "& input": {
            border: "1px solid #d7d8d6",
            borderRadius: "9px",
            height: "40px !important",
            padding: "7px 15px",
         },
         "& textarea": {
            border: "1px solid #d7d8d6",
            borderRadius: "9px",
            padding: "7px 15px",
         }
      }
   },
   noBorder: {
      borderBottom: "none",
   },
   btnAddFriend: {
      minWidth: "127px",
      backgroundColor: "#d8dddd",
      height: "41px",
      fontSize: "16px",
      color: "#1f7fb6 !important",
      fontWeight: "700",
      letterSpacing: "0",
      border: "1px solid #1f7fb6",
      borderRadius: "5px",
      textAlign: "center",
      textTransform: "none",
      margin: "0 4px",
      "& span": {
         color: "#1f7fb6 !important",
      }
   },
   circularLoader: {
      position: 'fixed',
      zIndex: 99,
      left: '60%',
      top: '50%',
      color: '#0388c9',
   },
   dialogLoader: {
      left: '50%',
      top: '50%',
   },
   editButton: {
      textDecoration: 'none!important',
      color: "#35a0d4 !important"
   }
} as any;
// Customizable Area End

const mapStateToProps = (state: any) => {
   return {
      chatClient: state.takeAction.clientData[0],
      profileData: state.takeAction.profileData,
      notificationsConfigurations: state.takeAction.notificationsConfigurations
   };
};

const mapDispatchToProps = (dispatch: any) => {
   return {
      userLogout: () => {
         dispatch(USER_LOGOUT())
      },
      userChange: () => {
         dispatch(USER_CHANGE())
      },
      eraseFriendList: () => {
         dispatch(DELETE_FRIENDLIST())
      },
      getProfile: (user: any) => {
         dispatch(GET_USER(user))
      },
      deleteChats: () => {
         dispatch(DELETE_CHATS())
      },
      setProfile: (profileData: any) => {
         dispatch(SET_PROFILE(profileData))
      },
      setNotificationsConfigurations: (configurationsDetails: any) => {
         dispatch(SET_NOTIFICATIONS_CONFIGURATIONS(configurationsDetails))
      }
   }
}
//@ts-ignore
const usersetting = withRouter(connect(mapStateToProps, mapDispatchToProps)(userSetting))
export default withStyles(useStyles)(usersetting)